<template>
  <div id="disasterEventSendComponent">
    <h1>安否確認画面</h1>
    <h2>安否確認状況</h2>
    <EventSelector
      v-if="!eventsLoading"
      :loadFailed="eventsLoadFailed"
      :events="events"
      @change="selectEvent"
    >
    </EventSelector>
    <SafetyConfirmationTableComponent
      v-if="!eventsLoading"
      :loading="answersLoading"
      :loadFailed="answersLoadFailed"
      :answers="selectedEventAnswers"
      @refresh="getAnswers"
      @resend-mail="resendMail"
    >
    </SafetyConfirmationTableComponent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getEvents } from "../apis/events";
import { getSafetyConfirmations } from "../apis/safetyConfirmations";
import { resendMailRequest } from "../apis/mails";

import EventSelector from "../components/EventSelector";
import SafetyConfirmationTableComponent from "../components/SafetyConfirmationTable";

export default {
  name: "SafetyConfirmationComponent",
  components: { EventSelector, SafetyConfirmationTableComponent },
  data: () => ({
    events: [],
    selectEventIndex: "",
    eventsLoading: true,
    eventsLoadFailed: false,
    answers: [],
    answersLoading: true,
    answersLoadFailed: false,
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    selectedEventAnswers() {
      if (this.events.length === 0 || this.selectEventIndex === "") {
        return [];
      }
      return this.answers.filter(
        (answer) =>
          answer.eventName === this.events[this.selectEventIndex - 1].text
      );
    },
  },
  methods: {
    ...mapActions(["initUserInfo"]),
    selectEvent(selectEventIndex) {
      this.selectEventIndex = selectEventIndex;
    },
    async getEvents() {
      const limit = 10;
      const result = await getEvents(this.corporateNumber, limit);
      this.eventsLoading = false;

      if (result.hasError) {
        this.eventsLoadFailed = true;
        // NOTE: イベントの取得に失敗した場合、表示するイベントの選択ができないため、
        // 回答データの取得をしても表示できないため、回答データの取得失敗フラグを立てる
        this.answersLoadFailed = true;
        alert(result.message);
        return;
      }

      this.events = result.items.map((item, index) => ({
        text: item.eventName,
        value: index + 1,
      }));
    },

    async getAnswers() {
      const result = await getSafetyConfirmations(this.corporateNumber);
      this.answersLoading = false;

      if (result.hasError) {
        this.answersLoadFailed = true;
        alert(result.message);
        return;
      }

      this.answers = result.items;
    },

    async resendMail(item) {
      const result = await resendMailRequest(this.corporateNumber, item);

      // キャンセルされた場合はmessageが空文字列
      if (result.message === "") {
        return;
      }

      alert(result.message);

      // NOTE: 再送信に成功した場合は再送信回数が更新されるため、回答データの再取得を実行
      if (!result.hasError) {
        this.getAnswers();
      }
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = `安否確認状況 | ${this.companyName} 管理画面`;
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }

    await Promise.all([this.getEvents(), this.getAnswers()]);
  },
};
</script>
