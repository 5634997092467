<template>
  <div>
    <v-btn color="success" @click="refresh">更新</v-btn>
    <v-data-table
      :headers="headers"
      :items="employees"
      :items-per-page="-1"
      sort-by="[employeeNo]"
      hide-default-footer
      class="elevation-1 mt-4 corporate-custom"
      :loading="loading"
    >
      <template v-slot:[`item.familyEmails`]="{ item }">
        <template v-for="(familyEmail, index) in item.familyEmails">
          {{ familyEmail }}<br :key="index" />
        </template>
      </template>
      <template v-slot:[`item.permissionLevel`]="{ item }">
        {{ permissionText(item.permissionLevel) }}
      </template>
      <template v-slot:[`item.branchOfficeId`]="{ item }">
        {{ branchOfficeName(item.branchOfficeId) }}
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon small class="mr-2" @click="openEditModal(item)">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:[`item.republishTemporaryPassword`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="openRepublishTemporaryPasswordDialog(item)"
        >
          mdi-lock-reset
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showDialog"
      scrollable
      draggable="true"
      persistent
      no-click-animation
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="mt-4">
        <v-card-title primary-title>
          {{ modalItem.currentName }}を編集
        </v-card-title>
        <v-card-text> ※電話番号は変更できません </v-card-text>
        <v-card-text>
          <v-text-field
            label="社員No."
            name="employeeNo"
            textarea
            v-model="modalItem.employeeNo"
          ></v-text-field>
          <v-text-field
            label="社員名"
            name="name"
            textarea
            v-model="modalItem.name"
          ></v-text-field>
          <v-text-field
            label="メールアドレス"
            name="email"
            textarea
            v-model="modalItem.email"
          ></v-text-field>
          <v-text-field
            label="家族のメールアドレス"
            :name="`familyEmails_${index}`"
            hint="複数設定する場合は,で区切って入力してください"
            textarea
            v-for="(_, index) in modalItem.familyEmails"
            :key="index"
            v-model="modalItem.familyEmails[index]"
          >
            <template v-slot:append-outer>
              <v-icon small class="mr-2" @click="appendFamilyEmails">
                mdi-plus
              </v-icon>
              <v-icon
                small
                class="mr-2"
                @click="removeFamilyEmails(index)"
                :disabled="modalItem.familyEmails.length <= 1"
              >
                mdi-minus
              </v-icon>
            </template>
          </v-text-field>
          <v-select
            label="主たる勤務都道府県"
            :items="prefs"
            item-text="prefName"
            v-model="modalItem.prefName"
          ></v-select>
          <v-select
            label="勤務拠点"
            :items="branchOfficesForSelect(modalItem.branchOfficeId)"
            item-text="name"
            item-value="branchOfficeId"
            v-model="modalItem.branchOfficeId"
            messages="※未設定に戻す機能は未実装です。"
          >
          </v-select>
          <v-select
            v-if="canEditPermissionLebel"
            label="権限レベル"
            :items="permissionLevelsForEdit"
            v-model="modalItem.permissionLevel"
          ></v-select>
          <v-select
            v-else
            label="権限レベル"
            disabled
            :items="permissionLevels"
            v-model="modalItem.permissionLevel"
            persistent-hint
            hint="特権ユーザの権限レベルは変更できません"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="saveItem" color="info">更新</v-btn>
          <v-btn @click="closeEditModal" color="error"> キャンセル </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showRepublishTemporaryPasswordDialog"
      scrollable
      draggable="true"
      persistent
      no-click-animation
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>{{ modalItem.name }}の仮パスワード再発行</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-btn
                width="100%"
                color="info"
                @click="republishTemporaryPassword(modalItem, false)"
              >
                一般ユーザー
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                width="100%"
                color="info"
                @click="republishTemporaryPassword(modalItem, true)"
                :disabled="modalItem.permissionLevel < 1"
              >
                管理者ユーザー
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                @click="closeRepublishTemporaryPasswordDialog"
                width="100%"
                color="error"
              >
                キャンセル
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import prefs from "../consts/prefs.js";
import {
  permissionLevels,
  permissionLevelsForEdit,
} from "../consts/permissionLevels.js";
export default {
  name: "EmployeesEditTableComponent",
  props: {
    company: Object,
    branchOffices: Array,
    employees: Array,
    loading: Boolean,
  },
  data: () => ({
    headers: [
      { text: "社員No.", value: "employeeNo" },
      { text: "社員名", value: "name" },
      { text: "携帯番号", value: "cellphoneNumber" },
      { text: "メールアドレス", value: "email" },
      { text: "家族のメールアドレス", value: "familyEmails" },
      { text: "主たる勤務都道府県", value: "prefName" },
      { text: "勤務拠点", value: "branchOfficeId" },
      { text: "権限レベル", value: "permissionLevel" },
      { text: "編集", value: "edit" },
      { text: "仮パスワード再発行", value: "republishTemporaryPassword" },
    ],
    showDialog: false,
    showRepublishTemporaryPasswordDialog: false,
    modalItem: {
      employeeNo: "",
      name: "",
      cellphoneNumber: "",
      email: "",
      familyEmails: [],
      prefName: "",
      prefCode: "",
      permissionLevel: "",
      branchOfficeId: "",
      branchOfficePrefCode: "",
      branchOfficePrefName: "",
    },
    prefs,
    permissionLevels,
    permissionLevelsForEdit,
  }),
  computed: {
    canEditPermissionLebel() {
      return this.modalItem.permissionLevel !== 2;
    },
  },
  methods: {
    branchOfficesForSelect(branchOfficeId) {
      if (branchOfficeId) return this.branchOffices;

      return [
        { name: "(未設定)", branchOfficeId: null },
        ...this.branchOffices,
      ];
    },
    permissionText(value) {
      return this.permissionLevels.find(
        (permissionLevel) => permissionLevel.value === value
      ).text;
    },
    branchOfficeName(branchOfficeId) {
      if (!branchOfficeId) return "未設定";

      return this.branchOffices.find(
        (item) => item.branchOfficeId === branchOfficeId
      ).name;
    },
    openEditModal(employee) {
      // NOTE:
      // objectからobjectの代入は参照渡しになり、モーダル側の変更内容が
      // テーブルの表示に反映されてしまう
      // 一度JSON文字列へ変換することで参照を切ることができる(deep copy)
      //
      // スプレッド構文ではトップレベルの要素を値渡しさせることができるが、
      // 入れ子になっているobjectやarrayは参照渡しのままになる
      // (このケースではfamilyEmailsが該当)
      this.modalItem = {
        ...JSON.parse(JSON.stringify(employee)),
        currentName: employee.name,
      };
      if (this.modalItem.familyEmails === undefined) {
        this.modalItem.familyEmails = [""];
      } else if (this.modalItem.familyEmails.length === 0) {
        this.modalItem.familyEmails.push("");
      }
      // 企業の会社所在地情報について、社員のレコードがその値を持っていればそのまま利用する
      // 社員のレコードが値を持っていなかった場合には、企業のレコードの値を利用する
      if (employee.companyPrefCode) {
        this.modalItem.companyPrefCode = employee.companyPrefCode;
      } else if (this.company.prefCode) {
        this.modalItem.companyPrefCode = this.company.prefCode;
      }
      if (employee.companyPrefName) {
        this.modalItem.companyPrefName = employee.companyPrefName;
      } else if (this.company.prefName) {
        this.modalItem.companyPrefName = this.company.prefName;
      }
      if (!employee.branchOfficeId) {
        this.modalItem.branchOfficeId = null;
      }
      this.showDialog = true;
    },
    closeEditModal() {
      this.showDialog = false;
      this.resetModalItem();
    },
    resetModalItem() {
      this.modalItem = {
        employeeNo: "",
        name: "",
        cellphoneNumber: "",
        email: "",
        familyEmails: [],
        prefName: "",
        prefCode: "",
        permissionLevel: "",
        branchOfficeId: null,
      };
    },
    saveItem() {
      this.modalItem.prefCode = parseInt(
        this.prefs.find((pref) => pref.prefName === this.modalItem.prefName)
          .prefCode,
        10
      );

      if (this.modalItem.branchOfficeId === null) {
        delete this.modalItem.branchOfficeId;
      } else {
        const branchOffice = this.branchOffices.find(
          (item) => item.branchOfficeId === this.modalItem.branchOfficeId
        );
        this.modalItem.branchOfficePrefCode = branchOffice.prefCode;
        this.modalItem.branchOfficePrefName = branchOffice.prefName;
      }

      // TODO: 入力値のバリデーション

      this.$emit("save", this.modalItem);
    },
    appendFamilyEmails() {
      this.modalItem.familyEmails.push("");
    },
    removeFamilyEmails(index) {
      this.modalItem.familyEmails.splice(index, 1);
    },
    refresh() {
      this.$emit("refresh");
    },
    openRepublishTemporaryPasswordDialog(employee) {
      this.showRepublishTemporaryPasswordDialog = true;
      this.modalItem = { ...employee };
    },
    closeRepublishTemporaryPasswordDialog() {
      this.resetModalItem;
      this.showRepublishTemporaryPasswordDialog = false;
    },
    republishTemporaryPassword(employee, isAdminPool) {
      if (confirm(`${employee.name}の仮パスワードを再発行しますか？`)) {
        this.$emit("republish-temporary-password", employee, isAdminPool);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/table.scss";
</style>
