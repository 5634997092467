import Vue from "vue";
import VueRouter from "vue-router";
import cognito from "@/cognito";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import PasswordReset from "../views/PasswordReset.vue";
import Top from "../views/Top.vue";
import PrivilegeEditCompany from "../views/PrivilegeEditCompany.vue";
import HealthReportSchedule from "../views/HealthReportSchedule.vue";
import PrivilegeSafetyConfirmation from "../views/PrivilegeSafetyConfirmation.vue";
import PrivilegeBodyTemperature from "../views/PrivilegeBodyTemperature.vue";
import PrivilegeDisasterEventSend from "../views/PrivilegeDisasterEventSend.vue";
import EditCompany from "../views/EditCompany.vue";
import SafetyConfirmation from "../views/SafetyConfirmation.vue";
import BodyTemperature from "../views/BodyTemperature.vue";
import DisasterEventSend from "../views/DisasterEventSend.vue";

Vue.use(VueRouter);

const requireAuth = async (to, from, next) => {
  if (await cognito.isAuthenticated()) {
    next();
    return;
  }

  next({
    path: "/SignIn",
    query: { redirect: to.fullPath },
  });
};

const alreadyAuthCheck = async (to, from, next) => {
  if (await cognito.isAuthenticated()) {
    next({ path: "/" });
    return;
  }

  next();
};

// NOTE: パスのマッチングは上から判定される
// 存在しないページに対するアクセス制御のためのワイルドカードを用いた記述は一番最後
const routes = [
  {
    path: "/",
    name: "Top",
    component: Top,
    beforeEnter: requireAuth,
  },
  {
    path: "/SignIn",
    component: SignIn,
    beforeEnter: alreadyAuthCheck,
  },
  {
    path: "/SignUp",
    component: SignUp,
    beforeEnter: alreadyAuthCheck,
  },
  {
    path: "/PasswordReset",
    name: "PasswordReset",
    component: PasswordReset,
    // NOTE: サインイン済みの状態でもパスワードリセットしたいかも？
    // 一旦ナビゲーションガードは無効にしておく
    // beforeEnter: alreadyAuthCheck,
  },
  {
    path: "/privilege/EditCompany",
    component: PrivilegeEditCompany,
    beforeEnter: requireAuth,
  },
  {
    path: "/privilege/HealthReportSchedule",
    component: HealthReportSchedule,
    beforeEnter: requireAuth,
  },
  {
    path: "/privilege/SafetyConfirmation",
    component: PrivilegeSafetyConfirmation,
    beforeEnter: requireAuth,
  },
  {
    path: "/privilege/BodyTemperature",
    component: PrivilegeBodyTemperature,
    beforeEnter: requireAuth,
  },
  {
    path: "/privilege/DisasterEventSend",
    name: "PrivilegeDisasterEventSend",
    component: PrivilegeDisasterEventSend,
    beforeEnter: requireAuth,
  },
  {
    path: "/EditCompany",
    component: EditCompany,
    beforeEnter: requireAuth,
  },
  {
    path: "/SafetyConfirmation",
    name: "SafetyConfirmation",
    component: SafetyConfirmation,
    beforeEnter: requireAuth,
  },
  {
    path: "/BodyTemperature",
    name: "BodyTemperature",
    component: BodyTemperature,
    beforeEnter: requireAuth,
  },
  {
    path: "/DisasterEventSend",
    name: "DisasterEventSend",
    component: DisasterEventSend,
    beforeEnter: requireAuth,
  },
  {
    path: "/*",
    // name:NotFound,
    // component:"NotFound",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
