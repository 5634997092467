<template>
  <div>
    <h2>登録済みのスケジュール</h2>
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="schedules"
            class="elevation-1 corporate-custom"
            :loading="loading"
          >
            <template v-slot:[`item.delete`]="{ item }">
              <v-icon small class="mr-2" @click="deleteSchedule(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "SchedulesTableComponent",
  props: {
    schedules: Array,
    loading: Boolean,
  },
  data: () => ({
    headers: [
      { text: "日付", value: "date" },
      { text: "曜日", value: "day" },
      { text: "時刻", value: "time" },
      { text: "削除", value: "delete" },
    ],
  }),
  methods: {
    deleteSchedule(schedule) {
      this.$emit("delete", schedule);
    },
  },
};
</script>
