<template>
  <div>
    <h1>企業情報編集画面</h1>
    <h2>企業情報</h2>
    <CompanyEditTableComponent
      ref="companyEditTable"
      :company="company"
      :loading="false"
      @save="saveCompany"
    ></CompanyEditTableComponent>
    <h2>拠点一覧</h2>
    <BranchOfficeRegisterComponent
      ref="branchOfficeRegister"
      :corporateNumber="company.corporateNumber"
      @save="registBranchOffice"
    ></BranchOfficeRegisterComponent>
    <BranchOfficeEditTableComponent
      ref="branchOfficeEditTable"
      :branchOffices="branchOffices"
      :loading="branchOfficesLoading"
      @save="saveBranchOffice"
      @delete="deleteBranchOffice"
    ></BranchOfficeEditTableComponent>
    <h2>社員一覧</h2>
    <EmployeesEditTableComponent
      ref="employeeEditTable"
      :company="company"
      :branchOffices="branchOffices"
      :employees="storedEmployees"
      :loading="employeesLoading"
      @save="saveEmployee"
      @refresh="refreshEmployees"
      @republish-temporary-password="republishTemporaryPassword"
    ></EmployeesEditTableComponent>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { updateCompany } from "../apis/companies";
import {
  getBranchOfficesByCorporateNumber,
  registBranchOffice,
  updateBranchOffice,
  deleteBranchOffice,
} from "../apis/branchOffices";
import { updateEmployee } from "../apis/employees";
import { republishTemporaryPassword } from "../apis/password";

import CompanyEditTableComponent from "../components/CompanyEditTable.vue";
import BranchOfficeRegisterComponent from "../components/BranchOfficeRegister.vue";
import BranchOfficeEditTableComponent from "../components/BranchOfficeEdit.vue";
import EmployeesEditTableComponent from "../components/EmployeesEditTable.vue";

export default {
  name: "EditCompany",
  components: {
    CompanyEditTableComponent,
    BranchOfficeRegisterComponent,
    BranchOfficeEditTableComponent,
    EmployeesEditTableComponent,
  },
  data: () => ({
    branchOffices: [],
    branchOfficesLoading: true,
    employeesLoading: true,
  }),
  computed: {
    ...mapGetters(["company", "permissionLevel"]),
    ...mapGetters({
      storedEmployees: "employees",
    }),
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchCompany", "fetchEmployees"]),
    async saveCompany(company) {
      const result = await updateCompany(company);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      // TODO: 自社の情報の取得し直し
      this.fetchCompany();
      this.$refs.companyEditTable.closeEditModal();
    },
    async getBranchOffices() {
      this.branchOfficesLoading = true;
      const result = await getBranchOfficesByCorporateNumber(
        this.company.corporateNumber
      );
      if (result.hasError) {
        this.branchOfficesLoading = false;
        alert(result.message);
        return;
      }
      this.branchOffices = result.items;
      this.branchOfficesLoading = false;
    },
    async registBranchOffice(branchOffice) {
      const result = await registBranchOffice(branchOffice);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getBranchOffices();
      this.$refs.branchOfficeRegister.closeRegistModal();
    },
    async saveBranchOffice(branchOffice) {
      const result = await updateBranchOffice(branchOffice);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getBranchOffices();
      this.$refs.branchOfficeEditTable.closeEditModal();
    },
    async deleteBranchOffice(branchOfficeKey) {
      const result = await deleteBranchOffice(branchOfficeKey);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getBranchOffices();
      this.$refs.branchOfficeEditTable.closeDeleteModal();
    },
    async getEmployees() {
      this.employeesLoading = true;
      this.fetchEmployees().then(() => (this.employeesLoading = false));
    },
    async saveEmployee(employee) {
      const result = await updateEmployee(employee);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getEmployees(employee.corporateNumber);
      this.$refs.employeeEditTable.closeEditModal();
    },
    async refreshEmployees() {
      await this.getEmployees(this.corporateNumber);
    },
    async republishTemporaryPassword(employee, isAdminPool) {
      const result = await republishTemporaryPassword(employee, isAdminPool);
      alert(result.message);
      if (!result.hasError) {
        this.$refs.employeeEditTable.closeRepublishTemporaryPasswordDialog();
      }
    },
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }

    if (this.storedEmployees.length === 0) {
      this.fetchEmployees().then(() => (this.employeesLoading = false));
    } else {
      this.employeesLoading = false;
    }
    this.getBranchOffices();
  },
};
</script>
<style scoped></style>
