<template>
  <div>
    <h2>送信対象</h2>
    <v-data-table
      :headers="headers"
      :items="employees"
      :sort-by="['employeeNo']"
      class="elevation-1 mt-4 corporate-custom"
      :items-per-page="-1"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:[`item.sendTarget`]="{ item }">
        <v-simple-checkbox
          v-model="item.sendTarget"
          @click="updateTargetEmployees"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "TargetEmployeesSelectorComponent",
  props: {
    employees: Array,
    loading: Boolean,
  },
  data: () => ({
    // NOTE: ソートに使えるのは社員No
    headers: [
      { text: "社員番号", value: "employeeNo" },
      { text: "社員名", value: "name", sortable: false },
      { text: "電話番号", value: "cellphoneNumber", sortable: false },
      { text: "メールアドレス", value: "email", sortable: false },
      { text: "送信対象", value: "sendTarget", sortable: false },
    ],
  }),
  computed: {
    targetEmployees() {
      return this.employees.filter(
        (targetItem) => targetItem.sendTarget === true
      );
    },
  },
  watch: {
    // NOTE: 社員の取得が完了したら各データに選択状態を示すフラグを追加する
    loading(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.employees.forEach((employee, i) => {
          this.$set(this.employees, i, { ...employee, sendTarget: true });
        });
        this.updateTargetEmployees();
      }
    },
  },
  methods: {
    updateTargetEmployees() {
      // NOTE: v-modelの双方向バインドを利用しているため、inputイベントを発火して更新
      this.$emit("input", this.targetEmployees);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/table.scss";
</style>
