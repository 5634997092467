import { API } from "aws-amplify";
const apiName = "protfupe_admin";

async function republishTemporaryPassword(employee, isAdminPool) {
  const result = {
    hasError: false,
    message: "",
  };

  const corporateNumber = employee.corporateNumber;
  const params = {
    userName: employee.uniqueName,
    isAdminPool,
  };

  const resourcePath = `/${corporateNumber}/employee/republish_temporary_password`;
  const myInit = {
    response: true,
    body: params,
  };

  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => e
  );

  if (apiResponse instanceof Error) {
    result.hasError = true;
    result.message = apiResponse.response.data.message;
    return result;
  }

  result.message = apiResponse.data.message;
  return result;
}

export { republishTemporaryPassword };
