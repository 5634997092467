<template>
  <div>
    <h1>体温確認状況</h1>
    <DeniedComponent
      v-if="isPermissionCheckCompleted && !isSuperUser"
    ></DeniedComponent>
    <template v-if="isSuperUser">
      <h2>対象の企業</h2>
      <v-select
        :items="companies"
        item-text="companyName"
        item-value="corporateNumber"
        v-model="selecetedCompanyCorporateNumber"
        label="参照する企業"
        @change="changeCompany"
        @resend-mail="sendHealthReportMailsRequest"
      ></v-select>
      <h2>体温確認状況</h2>
      <DateSelector @change="selectDate"> </DateSelector>
      <HealthReportTableComponent
        ref="healthReportTable"
        :loading="healthReportsLoading"
        :loadFailed="healthReportsLoadFailed"
        :healthReports="selectedDateHealthReports"
        @save="updateHealthReport"
        @refresh="getHealthReports"
        @resend-mail="sendHealthReportMailsRequest"
      >
      </HealthReportTableComponent>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { getCompanies } from "../apis/companies";
import { getEmployeesByCorporateNumber } from "../apis/employees";
import { getHealthReports, updateHealthReport } from "../apis/healthReports";
import {
  sendHealthReportMailsRequest,
  resendHealthReportMailsRequest,
} from "../apis/mails";

import { formatDate } from "../utils/format";

import DeniedComponent from "../components/Denied";
import DateSelector from "../components/DateSelector";
import HealthReportTableComponent from "../components/HealthReportTable";

export default {
  name: "PrivilegeBodyTemperature",
  components: {
    DeniedComponent,
    DateSelector,
    HealthReportTableComponent,
  },
  data: () => ({
    companies: [],
    selecetedCompanyCorporateNumber: "",
    companiesLoading: true,
    companiesLoadFailed: false,
    employees: [],
    employeesLoading: true,
    employeesLoadFailed: false,

    selectedDate: "",

    healthReports: [],
    healthReportsLoading: true,
    healthReportsLoadFailed: false,
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    isPermissionCheckCompleted() {
      return this.permissionLevel !== -1;
    },
    isSuperUser() {
      return this.permissionLevel === 2;
    },
    selecetedCompany() {
      if (this.selecetedCompanyCorporateNumber === "") {
        return {};
      }

      return this.companies.find(
        (company) =>
          company.corporateNumber === this.selecetedCompanyCorporateNumber
      );
    },
    formatToday() {
      return formatDate(new Date());
    },
    selectedDateHealthReports() {
      if (this.healthReportsLoading || this.healthReportsLoadFailed) {
        return [];
      }
      const existHealthReports = this.healthReports.filter(
        (item) => item.registDate === this.selectedDate
      );
      const answeredEmployeeCellphoneNumbers = existHealthReports.map(
        (report) => report.cellphoneNumber
      );
      const notAnsweredEmployees = this.employees.filter(
        (employee) =>
          !answeredEmployeeCellphoneNumbers.includes(employee.cellphoneNumber)
      );
      const dummyHealthReports = notAnsweredEmployees.map((employee) => ({
        cellphoneNumber: employee.cellphoneNumber,
        employeeNo: employee.employeeNo,
        employeeName: employee.name,
        email: employee.email,
        registDate: this.selectedDate,
      }));

      return existHealthReports.concat(dummyHealthReports);
    },
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchEmployee"]),
    async getCompanies() {
      const result = await getCompanies();
      this.companiesLoading = false;

      if (result.hasError) {
        this.companiesLoadFailed = true;
        alert(result.message);
        return;
      }

      this.companies = result.items;
      this.selecetedCompanyCorporateNumber = this.corporateNumber;
      this.companiesLoading = false;
    },
    async getHealthReports() {
      const escapedDate = this.formatToday.replace(/\//g, "_");
      const result = await getHealthReports(
        this.selecetedCompanyCorporateNumber,
        escapedDate,
        10
      );
      this.healthReportsLoading = false;

      if (result.hasError) {
        this.healthReportsLoadFailed = true;
        alert(result.message);
        return;
      }

      this.healthReports = result.items;
    },
    async getEmployees() {
      const result = await getEmployeesByCorporateNumber(
        this.selecetedCompanyCorporateNumber
      );
      this.employeesLoading = false;

      if (result.hasError) {
        this.employeesLoadFailed = true;
        alert(result.message);
        return;
      }

      this.employees = result.items;
    },

    async changeCompany() {
      await this.getHealthReports();
      await this.getEmployees();
    },
    selectDate(selectedDate) {
      this.selectedDate = selectedDate;
    },

    async updateHealthReport(healthReport) {
      const result = await updateHealthReport(
        this.selecetedCompanyCorporateNumber,
        healthReport
      );
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getHealthReports();
      this.$refs.healthReportTable.closeEditModal();
    },
    // NOTE: 体温報告メールの自動送信の実装がまだのため、手動送信用に仮置き
    // 再送信の場合にはtargetAddressが指定され、リクエスト先のAPIが再送信用のものに切り替わる
    async sendHealthReportMailsRequest(targetAddress = null) {
      // 全社員に送信
      if (targetAddress === null) {
        const result = await sendHealthReportMailsRequest(
          this.selecetedCompanyCorporateNumber
        );
        alert(result.message);
        return;
      }

      // 指定された社員にのみ再送信
      const result = await resendHealthReportMailsRequest(
        this.selecetedCompanyCorporateNumber,
        targetAddress
      );
      alert(result.message);
      return;
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = `体温確認状況 | ${this.companyName} 管理画面`;
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }
    if (this.permissionLevel < 2) {
      return;
    }
    // NOTE:
    // getCompaniesので選択中の法人番号が決定される都合上、
    // getCompaniesは並列で実行できない
    await this.getCompanies();
    await Promise.all([this.getEmployees(), this.getHealthReports()]);
  },
};
</script>
