<template>
  <div>
    <h1>特権ユーザー用イベント作成ページ</h1>
    <DeniedComponent
      v-if="isPermissionCheckCompleted && !isSuperUser"
    ></DeniedComponent>
    <template v-if="isSuperUser">
      <h2>対象の企業</h2>
      <v-select
        :items="companies"
        item-text="companyName"
        item-value="corporateNumber"
        v-model="selecetedCompanyCorporateNumber"
        label="対象の企業"
        @change="changeCompany"
      ></v-select>

      <h2>イベント種別</h2>
      <v-radio-group v-model="eventTypeSelected" mandatory>
        <v-radio
          v-for="(eventType, index) in eventTypes"
          :key="index"
          :label="eventType"
          :value="eventType"
        ></v-radio>
      </v-radio-group>

      <DisasterEventNameInputComponent
        v-model="eventName"
        :companyName="selecetedCompany.companyName"
      ></DisasterEventNameInputComponent>

      <TargetEmployeesSelectorComponent
        v-model="targetEmployees"
        :loading="employeesLoading"
        :employees="employees"
      >
      </TargetEmployeesSelectorComponent>

      <v-btn color="primary" :disabled="!isValid" @click="postSendMail">
        送信
      </v-btn>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { getCompanies } from "../apis/companies";
import { getEmployeesByCorporateNumber } from "../apis/employees";
import { sendMailRequest } from "../apis/mails";

import { formatDatetime } from "../utils/format";

import DeniedComponent from "../components/Denied";
import DisasterEventNameInputComponent from "../components/DisasterEventNameInput";
import TargetEmployeesSelectorComponent from "../components/TargetEmployeesSelector";

export default {
  name: "PrivilegeDisasterEventSend",
  components: {
    DeniedComponent,
    DisasterEventNameInputComponent,
    TargetEmployeesSelectorComponent,
  },
  data: () => ({
    companies: [],
    selecetedCompanyCorporateNumber: "",
    companiesLoading: true,
    companiesLoadFailed: false,
    employees: [],
    employeesLoading: true,
    employeesLoadFailed: false,

    eventName: "",
    eventTypes: ["訓練", "災害安否確認"],
    eventTypeSelected: "訓練",
    targetEmployees: [],
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    isPermissionCheckCompleted() {
      return this.permissionLevel !== -1;
    },
    isSuperUser() {
      return this.permissionLevel === 2;
    },
    selecetedCompany() {
      if (this.selecetedCompanyCorporateNumber === "") {
        return {};
      }

      return this.companies.find(
        (company) =>
          company.corporateNumber === this.selecetedCompanyCorporateNumber
      );
    },
    isValid() {
      return (
        this.eventName.length > 0 &&
        this.targetEmployees.length > 0 &&
        this.selecetedCompanyCorporateNumber !== ""
      );
    },
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchEmployee"]),
    async getCompanies() {
      const result = await getCompanies();
      this.companiesLoading = false;

      if (result.hasError) {
        this.companiesLoadFailed = true;
        alert(result.message);
        return;
      }

      this.companies = result.items;
      this.selecetedCompanyCorporateNumber = this.corporateNumber;
      this.companiesLoading = false;
    },
    async getEmployees() {
      this.employeesLoading = true;
      const result = await getEmployeesByCorporateNumber(
        this.selecetedCompanyCorporateNumber
      );
      this.employeesLoading = false;

      if (result.hasError) {
        this.employeesLoadFailed = true;
        alert(result.message);
        return;
      }

      this.employees = result.items;
    },

    async changeCompany() {
      await this.getEmployees();
    },

    async postSendMail() {
      const params = {
        companyName: this.selecetedCompany.companyName,
        eventName: this.eventName,
        eventType: this.eventTypeSelected,
        targetEmployees: this.targetEmployees,
        date: formatDatetime(new Date()),
      };
      const result = await sendMailRequest(
        this.selecetedCompanyCorporateNumber,
        params
      );

      alert(result.message);
      return;
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = `地震以外災害用メール送信画面 | ${this.companyName} 管理画面`;
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }
    if (this.permissionLevel < 2) {
      return;
    }

    // NOTE:
    // getCompaniesので選択中の法人番号が決定される都合上、
    // getCompaniesは並列で実行できない
    await this.getCompanies();
    await this.getEmployees();
  },
};
</script>
