<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="branchOffices"
      :items-per-page="-1"
      sort-by="[branchOfficeId]"
      hide-default-footer
      class="elevation-1 mt-4 corporate-custom"
      :loading="loading"
    >
      <!-- TODO: 編集機能はAPI含め未作成 -->
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon small class="mr-2" @click="openEditModal(item)">
          mdi-pencil
        </v-icon>
      </template>
      <!-- TODO: 削除機能はAPI含め未作成 -->
      <template v-slot:[`item.delete`]="{ item }">
        <v-icon small class="mr-2" @click="openDeleteModal(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDialog"
      scrollable
      draggable="true"
      no-click-animation
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="mt-4">
        <v-card-title primary-title>
          拠点ID:{{ modalItem.branchOfficeId }}を編集
        </v-card-title>
        <v-card-text> ※法人番号・拠点IDは変更できません </v-card-text>
        <v-card-text>
          <v-text-field
            label="拠点名"
            name="name"
            textarea
            v-model="modalItem.name"
          >
          </v-text-field>
          <v-text-field
            label="住所"
            name="address"
            textarea
            v-model="modalItem.address"
          >
          </v-text-field>
          <v-select
            label="都道府県"
            :items="prefs"
            item-text="prefName"
            v-model="modalItem.prefName"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="saveItem" color="info">更新</v-btn>
          <v-btn @click="closeEditModal" color="error"> キャンセル </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteDialog"
      scrollable
      draggable="true"
      no-click-animation
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="mt-4">
        <v-card-title primary-title> 拠点情報削除 </v-card-title>
        <v-card-text>
          {{ modalItem.name }}(拠点ID:{{
            modalItem.branchOfficeId
          }})を削除しますか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteItem" color="info">削除する</v-btn>
          <v-btn @click="closeDeleteModal" color="error"> キャンセル </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prefs from "../consts/prefs.js";
export default {
  name: "BranchOfficeEditTableComponent",
  props: {
    /**
     * @typedef {{
     *   corporateNumber: string;
     *   branchOfficeId: string;
     *   name: string;
     *   address: string;
     *   prefCode: number;
     *   prefName: string;
     * }} BranchOfficeRecord
     *   拠点情報のレコード
     * @type {BranchOfficeRecord[]} 拠点の配列
     */
    branchOffices: Array,
    loading: Boolean,
  },
  data: () => ({
    headers: [
      { text: "拠点ID", value: "branchOfficeId" },
      { text: "拠点名", value: "name" },
      { text: "住所", value: "address" },
      { text: "都道府県", value: "prefName" },
      { text: "編集", value: "edit" },
      { text: "削除", value: "delete" },
    ],
    showDialog: false,
    showDeleteDialog: false,
    modalItem: {
      corporateNumber: "",
      branchOfficeId: "",
      name: "",
      address: "",
      prefName: "",
      prefCode: null,
    },
    prefs,
  }),
  methods: {
    /**
     * @param {BranchOfficeRecord} item 編集対象のレコード
     */
    openEditModal(item) {
      this.modalItem = { ...item };
      this.showDialog = true;
    },
    closeEditModal() {
      this.showDialog = false;
      this.resetModalItem();
    },
    /**
     * @param {BranchOfficeRecord} item 削除対象のレコード
     */
    openDeleteModal(item) {
      this.modalItem = { ...item };
      this.showDeleteDialog = true;
    },
    closeDeleteModal() {
      this.showDeleteDialog = false;
      this.resetModalItem();
    },
    resetModalItem() {
      this.modalItem = {
        corporateNumber: "",
        branchOfficeId: "",
        name: "",
        address: "",
        prefName: "",
        prefCode: null,
      };
    },
    saveItem() {
      this.modalItem.prefCode = parseInt(
        this.prefs.find((pref) => pref.prefName === this.modalItem.prefName)
          .prefCode,
        10
      );

      // TODO: 入力値のバリデーション

      this.$emit("save", this.modalItem);
    },
    deleteItem() {
      const key = {
        corporateNumber: this.modalItem.corporateNumber,
        branchOfficeId: this.modalItem.branchOfficeId,
      };
      this.$emit("delete", key);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/table.scss";
</style>
