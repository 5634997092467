const permissionLevels = [
  { text: "一般ユーザー", value: 0 },
  { text: "管理者ユーザー", value: 1 },
  { text: "特権ユーザー", value: 2 },
];
const permissionLevelsForEdit = [
  { text: "一般ユーザー", value: 0 },
  { text: "管理者ユーザー", value: 1 },
  // { text: "特権ユーザー", value: 2 },
];
export { permissionLevels, permissionLevelsForEdit };
