import { API } from "aws-amplify";
const apiName = "protfupe_admin";

/**
 * @typedef {{
 *   corporateNumber: string;
 *   branchOfficeId: string;
 * }} BranchOfficeKey
 *
 * @typedef {{
 *   corporateNumber: string;
 *   branchOfficeId: string;
 *   name: string;
 *   address: string;
 *   prefCode: number;
 *   prefName: string;
 * }} BranchOfficeRecord
 */

/**
 * 法人番号に紐付く拠点データを全て取得
 *
 * @param {string} corporateNumber
 * @returns {Promise<{hasError:boolean,message:string,items:BranchOfficeRecord[]}>}
 */
export async function getBranchOfficesByCorporateNumber(corporateNumber) {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = `/${corporateNumber}/branchOffices`;
  const myInit = {
    response: true,
  };

  try {
    const apiResponse = await API.get(apiName, resourcePath, myInit);
    result.items = apiResponse.data.records;
    return result;
  } catch (error) {
    result.hasError = true;
    result.message = "拠点データの取得に失敗しました。";
    return result;
  }
}

/**
 * 拠点データの新規登録
 *
 * @param {BranchOfficeRecord} branchOffice
 * @returns {Promise<{hasError:boolean,message:string}>}
 */
export async function registBranchOffice(branchOffice) {
  const { corporateNumber, branchOfficeId, ...body } = branchOffice;
  const result = {
    hasError: false,
    message: "",
  };

  // キー項目である法人番号・拠点IDはパスパラメータ
  // それ以外の項目はリクエストボディで送る
  const resourcePath = `/${corporateNumber}/branchOffice/${branchOfficeId}`;
  const myInit = {
    response: true,
    body,
  };

  try {
    await API.put(apiName, resourcePath, myInit);
    return result;
  } catch (error) {
    result.hasError = true;
    result.message = "拠点データの登録に失敗しました。";
    return result;
  }
}

/**
 * 拠点データの更新
 *
 * @param {BranchOfficeRecord} branchOffice
 * @returns {Promise<{hasError:boolean,message:string}>}
 */
export async function updateBranchOffice(branchOffice) {
  const { corporateNumber, branchOfficeId, ...body } = branchOffice;
  const result = {
    hasError: false,
    message: "",
  };

  // キー項目である法人番号・拠点IDはパスパラメータ
  // それ以外の項目はリクエストボディで送る
  const resourcePath = `/${corporateNumber}/branchOffice/${branchOfficeId}`;
  const myInit = {
    response: true,
    body,
  };

  try {
    await API.post(apiName, resourcePath, myInit);
    return result;
  } catch (error) {
    result.hasError = true;
    result.message = "拠点データの更新に失敗しました。";
    return result;
  }
}

/**
 * 拠点データの削除
 *
 * @param {BranchOfficeKey} key
 * @returns {Promise<{hasError:boolean,message:string}>}
 */
export async function deleteBranchOffice(key) {
  const { corporateNumber, branchOfficeId } = key;
  const result = {
    hasError: false,
    message: "",
  };

  // キー項目である法人番号・拠点IDはパスパラメータで送る
  const resourcePath = `/${corporateNumber}/branchOffice/${branchOfficeId}`;
  const myInit = {
    response: true,
  };

  try {
    await API.del(apiName, resourcePath, myInit);
    return result;
  } catch (error) {
    result.hasError = true;
    result.message = "拠点データの削除に失敗しました。";
    return result;
  }
}
