<template>
  <div>
    <v-btn color="success" @click="openRegistModal">新規登録</v-btn>
    <v-dialog
      v-model="showDialog"
      scrollable
      draggable="true"
      persistent
      no-click-animation
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-form ref="form">
        <v-card class="mt-4">
          <v-card-title primary-title> 拠点登録 </v-card-title>
          <v-card-text> ※拠点IDは登録後には修正できません。</v-card-text>
          <v-card-text>
            <v-text-field
              label="法人番号"
              name="corporateNumber"
              textarea
              v-model="modalItem.corporateNumber"
              disabled
            ></v-text-field>
            <v-text-field
              label="拠点ID"
              name="branchOfficeId"
              textarea
              v-model="modalItem.branchOfficeId"
              :rules="[inputRules.required]"
            ></v-text-field>
            <v-text-field
              label="拠点名"
              name="name"
              textarea
              v-model="modalItem.name"
              :rules="[inputRules.required]"
            ></v-text-field>
            <v-select
              label="拠点所在地"
              :items="prefs"
              item-text="prefName"
              v-model="modalItem.prefName"
              :rules="[inputRules.required]"
            ></v-select>
            <v-text-field
              label="拠点住所"
              name="address"
              textarea
              v-model="modalItem.address"
              :rules="[inputRules.required]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="saveItem" color="info">登録</v-btn>
            <v-btn @click="closeRegistModal" color="error">キャンセル</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import prefs from "../consts/prefs.js";
export default {
  name: "BranchOfficeRegisterComponent",
  props: {
    corporateNumber: String,
  },
  data: () => ({
    showDialog: false,
    modalItem: {
      corporateNumber: "",
      branchOfficeId: "",
      name: "",
      prefName: "",
      prefCode: null,
      address: "",
    },
    prefs,
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
    },
  }),
  methods: {
    openRegistModal() {
      this.modalItem.corporateNumber = this.corporateNumber;
      this.showDialog = true;
    },
    closeRegistModal() {
      this.showDialog = false;
      this.$refs.form.resetValidation();
      this.resetModalItem();
    },
    resetModalItem() {
      this.modalItem = {
        corporateNumber: "",
        branchOfficeId: "",
        name: "",
        prefName: "",
        prefCode: null,
        address: "",
      };
    },
    saveItem() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります");
        return;
      }

      this.modalItem.prefCode = parseInt(
        this.prefs.find((pref) => pref.prefName === this.modalItem.prefName)
          .prefCode,
        10
      );
      this.$emit("save", this.modalItem);
    },
  },
};
</script>
