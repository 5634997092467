<template>
  <div>
    <h1>安否確認画面</h1>
    <DeniedComponent
      v-if="isPermissionCheckCompleted && !isSuperUser"
    ></DeniedComponent>
    <template v-if="isSuperUser">
      <h2>対象の企業</h2>
      <v-select
        :items="companies"
        item-text="companyName"
        item-value="corporateNumber"
        v-model="selecetedCompanyCorporateNumber"
        label="参照する企業"
        @change="changeCompany"
      ></v-select>
      <h2>安否確認状況</h2>
      <EventSelector
        v-if="!eventsLoading"
        :loadFailed="eventsLoadFailed"
        :events="events"
        @change="selectEvent"
      >
      </EventSelector>
      <SafetyConfirmationTableComponent
        v-if="!eventsLoading"
        :loading="answersLoading"
        :loadFailed="answersLoadFailed"
        :answers="selectedEventAnswers"
        @refresh="getAnswers"
        @resend-mail="resendMail"
      >
      </SafetyConfirmationTableComponent>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { getCompanies } from "../apis/companies";

import { getEvents } from "../apis/events";
import { getSafetyConfirmations } from "../apis/safetyConfirmations";
import { resendMailRequest } from "../apis/mails";

import DeniedComponent from "../components/Denied";
import EventSelector from "../components/EventSelector";
import SafetyConfirmationTableComponent from "../components/SafetyConfirmationTable";

export default {
  name: "PrivilegeSafetyConfirmation",
  components: {
    DeniedComponent,
    EventSelector,
    SafetyConfirmationTableComponent,
  },
  data: () => ({
    companies: [],
    selecetedCompanyCorporateNumber: "",
    companiesLoading: true,
    companiesLoadFailed: false,
    events: [],
    selectEventIndex: "",
    eventsLoading: true,
    eventsLoadFailed: false,
    answers: [],
    answersLoading: true,
    answersLoadFailed: false,
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    isPermissionCheckCompleted() {
      return this.permissionLevel !== -1;
    },
    isSuperUser() {
      return this.permissionLevel === 2;
    },
    selecetedCompany() {
      if (this.selecetedCompanyCorporateNumber === "") {
        return {};
      }

      return this.companies.find(
        (company) =>
          company.corporateNumber === this.selecetedCompanyCorporateNumber
      );
    },
    selectedEventAnswers() {
      if (this.events.length === 0 || this.selectEventIndex === "") {
        return [];
      }
      return this.answers.filter(
        (answer) =>
          answer.eventName === this.events[this.selectEventIndex - 1].text
      );
    },
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchEmployee"]),
    async getCompanies() {
      const result = await getCompanies();
      this.companiesLoading = false;

      if (result.hasError) {
        this.companiesLoadFailed = true;
        alert(result.message);
        return;
      }

      this.companies = result.items;
      this.selecetedCompanyCorporateNumber = this.corporateNumber;
      this.companiesLoading = false;
    },
    async getEvents() {
      const limit = 10;
      const result = await getEvents(
        this.selecetedCompanyCorporateNumber,
        limit
      );
      this.eventsLoading = false;

      if (result.hasError) {
        this.eventsLoadFailed = true;
        // NOTE: イベントの取得に失敗した場合、表示するイベントの選択ができないため、
        // 回答データの取得をしても表示できないため、回答データの取得失敗フラグを立てる
        this.answersLoadFailed = true;
        alert(result.message);
        return;
      }

      this.events = result.items.map((item, index) => ({
        text: item.eventName,
        value: index + 1,
      }));
    },
    async getAnswers() {
      const result = await getSafetyConfirmations(
        this.selecetedCompanyCorporateNumber
      );
      this.answersLoading = false;

      if (result.hasError) {
        this.answersLoadFailed = true;
        alert(result.message);
        return;
      }

      this.answers = result.items;
    },

    async changeCompany() {
      await this.getEvents();
      await this.getAnswers();
    },
    selectEvent(selectEventIndex) {
      this.selectEventIndex = selectEventIndex;
    },

    async resendMail(item) {
      const result = await resendMailRequest(
        this.selecetedCompanyCorporateNumber,
        item
      );

      // キャンセルされた場合はmessageが空文字列
      if (result.message === "") {
        return;
      }

      alert(result.message);

      // NOTE: 再送信に成功した場合は再送信回数が更新されるため、回答データの再取得を実行
      if (!result.hasError) {
        this.getAnswers();
      }
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = `安否確認状況 | ${this.companyName} 管理画面`;
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }
    if (this.permissionLevel < 2) {
      return;
    }

    // NOTE:
    // getCompaniesので選択中の法人番号が決定される都合上、
    // getCompaniesは並列で実行できない
    await this.getCompanies();
    await Promise.all([this.getEvents(), this.getAnswers()]);
  },
};
</script>
