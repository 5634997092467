<template>
  <div>
    <h2>回答状況</h2>
    <v-btn color="success" @click="sendHealthReportMailsRequest()">
      回答要請メール送信
    </v-btn>
    <v-card>
      <v-alert type="error" :value="loadFailed">
        安否回答データの取得に失敗しました
      </v-alert>

      <v-data-table
        :headers="headers"
        :sort-by="['employeeNo']"
        :items="healthReports"
        :items-per-page="-1"
        hide-default-footer
        :loading="loading"
        :loading-text="loadingText"
        class="elevation-1 mt-4 corporate-custom answer-table"
      >
        <template v-slot:item="{ item }">
          <tr :class="{ 'row-not-answered': isNotAnswered(item) }">
            <td>{{ item.employeeNo }}</td>
            <td>{{ item.employeeName }}</td>
            <td class="cell-change-color cell-change-content">
              <span :class="getStyleBodyTemperature(item.bodyTemperature)">
                {{ item.bodyTemperature }}
              </span>
            </td>
            <td class="wide-cell cell-change-color cell-change-content">
              {{ item.freeEntryField }}
            </td>
            <td class="cell-change-color cell-change-content">
              {{ item.lastUpdateDate }}
            </td>
            <td>
              <v-icon small class="mr-2" @click="openEditModal(item)">
                mdi-pencil
              </v-icon>
            </td>
            <!-- NOTE: 回答済みであっても再送信ボタンの表示は行う -->
            <td>
              <v-icon
                small
                class="mr-2"
                @click="sendHealthReportMailsRequest(item.email)"
                :disabled="item.registDate !== formatToday"
              >
                mdi-email
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog
        v-model="showDialog"
        scrollable
        draggable="true"
        persistent
        no-click-animation
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-form ref="form">
          <v-card class="mt-4">
            <v-card-title primary-title>
              {{ modalItem.registDate }}
              {{ modalItem.employeeName }}の体温を修正
            </v-card-title>
            <v-card-text>
              <v-select
                :items="bodyTemperatures"
                v-model="modalItem.bodyTemperature"
                :rules="[inputRules.required]"
                label="体温"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="saveItem" color="info">修正</v-btn>
              <v-btn @click="closeEditModal" color="error"> キャンセル </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import { bodyTemperatures } from "../consts/bodyTemperatures";
import { formatDate, formatDatetime } from "../utils/format";
export default {
  name: "HealthReportTableComponent",
  props: {
    loading: Boolean,
    loadFailed: Boolean,
    healthReports: Array,
  },
  data: () => ({
    loadingText: "回答状況データを取得中です",
    // NOTE: ソートに使えるのは社員Noと最終更新日時
    headers: [
      { text: "社員番号", value: "employeeNo" },
      { text: "社員名", value: "employeeName", sortable: false },
      { text: "体温", value: "bodyTemperature", sortable: false },
      { text: "自由記入欄", value: "freeEntryField", sortable: false },
      { text: "最終更新日時", value: "lastUpdateDate" },
      { text: "修正", value: "edit", sortable: false },
      { text: "再送信", value: "resend", sortable: false },
    ],
    showDialog: false,
    modalItem: {
      cellphoneNumber: "",
      registDate: "",
      email: "",
      employeeName: "",
      employeeNo: "",
      bodyTemperature: "",
      freeEntryField: "",
      lastUpdateDate: "",
    },
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
    },
    bodyTemperatures,
  }),
  computed: {
    formatToday() {
      return formatDate(new Date());
    },
  },
  methods: {
    openEditModal(healthReport) {
      this.modalItem = {
        ...healthReport,
      };
      this.showDialog = true;
    },
    closeEditModal() {
      this.showDialog = false;
      this.$refs.form.resetValidation();
      this.resetModalItem();
    },
    resetModalItem() {
      this.modalItem = {
        cellphoneNumber: "",
        registDate: "",
        email: "",
        employeeName: "",
        employeeNo: "",
        bodyTemperature: "",
        freeEntryField: "",
        lastUpdateDate: "",
      };
    },
    saveItem() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります");
        return;
      }
      this.modalItem.lastUpdateDate = formatDatetime(new Date());
      this.$emit("save", this.modalItem);
    },
    emitRefresh() {
      this.$emit("refresh");
    },
    sendHealthReportMailsRequest(targetAddress = null) {
      this.$emit("resend-mail", targetAddress);
    },
    getStyleBodyTemperature(bodyTemperature) {
      return bodyTemperature >= 37.5 ? "cell-dangerous" : "";
    },
    isNotAnswered(healthReport) {
      return healthReport.bodyTemperature === undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/table.scss";
</style>
