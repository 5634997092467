<template>
  <div>
    <v-container fluid>
      <h1>TOPページ</h1>

      <template v-if="!latestEventAnswersLoading && !latestEventLoadFailed">
        <h2>■{{ latestEvent.eventName }}:における回答状況</h2>
        <p>イベント作成日:{{ latestEvent.registDate }}</p>
        <p>
          回答数: {{ latestEventAnsweredCount }} / 送信数:
          {{ latestEvent.notifiedCount }} / 回答率:
          {{ latestEventAnsweredRate }}%
        </p>
      </template>
      <router-link to="/SafetyConfirmation">安否確認へ</router-link>

      <template v-if="!healthAnswersLoading && !healthAnswersLoadFailed">
        <h2 class="mt-4">■過去3営業日の回答状況</h2>
        <p v-for="item in healthAnswerSummaries" :key="item.registDate">
          {{ item.registDate }}:{{ item.count }}件
          <span v-if="item.alertFlag" class="danger">発熱者がいます</span>
        </p>
      </template>
      <router-link to="/BodyTemperature">体温確認へ</router-link>

      <h2 class="mt-4">■地震以外災害メール送信</h2>
      <router-link to="/DisasterEventSend">送信画面へ</router-link>
      <!-- NOTE 後で実装するかも -->
      <!-- <h2 class="mt-4">■掲示板</h2>
      <p><a href="">掲示板閲覧</a></p>
      <p><a href="">掲示板編集</a></p> -->
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getEvents } from "../apis/events";
import { getSafetyConfirmations } from "../apis/safetyConfirmations";
import { getHealthReports } from "../apis/healthReports";
import { formatDate } from "../utils/format";

export default {
  name: "TopComponent",
  data: () => ({
    latestEvent: {},
    latestEventLoadFailed: false,
    latestEventAnswers: [],
    latestEventAnswersLoading: true,
    healthAnswers: [],
    healthAnswersLoading: true,
    healthAnswersLoadFailed: false,
    // healthAnswerSummaries: [],
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    latestEventAnsweredCount() {
      return this.latestEventAnswers.filter(
        (item) =>
          item.eventName === this.latestEvent.eventName && item.isAnswered
      ).length;
    },
    latestEventAnsweredRate() {
      return Math.floor(
        (this.latestEventAnsweredCount / this.latestEvent.notifiedCount) * 100
      );
    },
    healthAnswerSummaries() {
      const registDates = [...Array(3)].map((_, i) => {
        const currentDate = new Date();
        // 日付をi日前にずらす
        currentDate.setDate(currentDate.getDate() - i);
        return formatDate(currentDate);
      });

      return registDates.map((registDate) => {
        const oneDayHealthAnswer = this.healthAnswers.filter(
          (item) => item.registDate === registDate
        );

        return {
          registDate,
          count: oneDayHealthAnswer.length,
          alertFlag: oneDayHealthAnswer.some(
            (item) => item.bodyTemperature >= 37.5
          ),
        };
      });
    },
  },
  methods: {
    ...mapActions(["initUserInfo"]),
    async getLatestEvent() {
      const limit = 1;

      const result = await getEvents(this.corporateNumber, limit);
      if (result.hasError) {
        this.latestEventLoadFailed = true;
        alert(result.message);
        return;
      }

      this.latestEvent = result.items[0];
    },
    async getLatestEventAnswers() {
      if (this.latestEventLoadFailed) {
        this.latestEventAnswersLoading = false;
        return;
      }

      const result = await getSafetyConfirmations(this.corporateNumber);
      if (result.hasError) {
        this.latestEventLoadFailed = true;
        this.latestEventAnswersLoading = false;
        alert(result.message);
        return;
      }

      this.latestEventAnswers = result.items;
      this.latestEventAnswersLoading = false;
    },
    async getHealthReports() {
      const escapedDate = formatDate(new Date()).replace(/\//g, "_");
      const result = await getHealthReports(
        this.corporateNumber,
        escapedDate,
        3
      );
      if (result.hasError) {
        this.healthAnswersLoadFailed = true;
        this.healthAnswersLoading = false;
        alert(result.message);
        return;
      }
      this.healthAnswers = result.items;
      this.healthAnswersLoading = false;
    },
  },
  // タイトルの設定
  beforeMount() {
    document.title = `TOPページ | ${this.companyName} 管理画面`;
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }
    await Promise.all([
      this.getLatestEvent(),
      this.getLatestEventAnswers(),
      this.getHealthReports(),
    ]);
  },
};
</script>
<style scoped>
.danger {
  color: red;
  font-weight: bold;
}
</style>
