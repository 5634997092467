<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="[company]"
      hide-default-footer
      class="elevation-1 corporate-custom"
      :loading="loading"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon small class="mr-2" @click="openEditModal(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showDialog"
      scrollable
      draggable="true"
      persistent
      no-click-animation
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-form ref="form">
        <v-card class="mt-4">
          <v-card-title primary-title>
            {{ modalItem.currentName }}を編集
          </v-card-title>
          <v-card-text> ※法人番号は変更できません </v-card-text>
          <v-card-text>
            <v-text-field
              label="企業名"
              name="companyName"
              textarea
              v-model="modalItem.companyName"
              :rules="[inputRules.required]"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-select
              :items="prefs"
              item-text="prefName"
              v-model="modalItem.prefName"
              label="会社所在地"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="saveItem" color="info">更新</v-btn>
            <v-btn @click="closeEditModal" color="error"> キャンセル </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import prefs from "../consts/prefs.js";
export default {
  name: "CompanyEditTableComponent",
  props: {
    company: Object,
    loading: Boolean,
  },
  data: () => ({
    headers: [
      { text: "法人番号", value: "corporateNumber" },
      { text: "企業名", value: "companyName" },
      { text: "会社所在地", value: "prefName" },
      { text: "編集", value: "edit" },
    ],
    showDialog: false,
    modalItem: {
      corporateNumber: "",
      companyName: "",
      prefName: "",
      prefCode: "",
    },
    prefs,
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
    },
  }),
  methods: {
    openEditModal(company) {
      this.modalItem = {
        ...company,
        currentName: company.companyName,
      };
      this.showDialog = true;
    },
    closeEditModal() {
      this.showDialog = false;
      this.$refs.form.resetValidation();
      this.resetModalItem();
    },
    resetModalItem() {
      this.modalItem = {
        corporateNumber: "",
        companyName: "",
      };
    },
    saveItem() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります");
        return;
      }

      this.modalItem.prefCode = this.prefs.find(
        (pref) => pref.prefName === this.modalItem.prefName
      ).prefCode;
      this.$emit("save", this.modalItem);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/table.scss";
</style>
