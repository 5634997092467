import { API } from "aws-amplify";
const apiName = "protfupe_admin";

async function getEmployee() {
  const result = {
    hasError: false,
    message: "",
    item: {},
  };

  const resourcePath = `/employee/`;
  const myInit = {
    response: true,
  };

  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    result.message = "社員データの取得に失敗しました。";
    return result;
  }

  result.item = apiResponse.data.Item;
  return result;
}

// 法人番号に紐付く社員データを全て取得
// Lambda側でトークンの中から取り出した法人番号と指定した法人番号が違う場合は特権ユーザーのみ許可
async function getEmployeesByCorporateNumber(corporateNumber) {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = `/${corporateNumber}/employees`;
  const myInit = {
    response: true,
  };

  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    result.message = "社員データの取得に失敗しました。";
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}

async function updateEmployee(employee) {
  const result = {
    hasError: false,
    message: "",
  };

  const { corporateNumber, cellphoneNumber, ...updateAttributes } = employee;

  // Cognitoのユーザ名(uniqueName)は変更不可のため、更新対象の属性から除外する
  delete updateAttributes.uniqueName;

  // NOTE: セキュリティの都合上電話番号はパスパラメータではなくリクエストボディから渡す
  const resourcePath = `/${corporateNumber}/employee`;
  const myInit = {
    response: true,
    body: { cellphoneNumber, ...updateAttributes },
  };
  try {
    await API.post(apiName, resourcePath, myInit);
  } catch (e) {
    result.hasError = true;
    result.message = "社員情報の更新に失敗しました。";
  }

  return result;
}

export { getEmployee, getEmployeesByCorporateNumber, updateEmployee };
