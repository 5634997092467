import Vue from "vue";
import Vuex from "vuex";
import { getCompany } from "../apis/companies";
import { getEmployee, getEmployeesByCorporateNumber } from "../apis/employees";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    company: {
      corporateNumber: "",
      companyName: "",
      prefName: "",
      prefCode: "",
    },
    permissionLevel: -1,
    employees: [],
  },
  // stateを直接触らせず、getters経由で取得させる方が安全な実装
  getters: {
    // サインインしているユーザー自身に関する情報
    company: (state) => state.company,
    corporateNumber: (state) => state.company.corporateNumber,
    companyName: (state) => state.company.companyName,
    prefName: (state) => state.company.prefName,
    prefCode: (state) => state.company.prefCode,
    permissionLevel: (state) => state.permissionLevel,
    employees: (state) => state.employees,
  },
  mutations: {
    setCompany: (state, companyItem) => {
      state.company = { ...companyItem };
    },
    resetCompany: (state) => {
      state.company = {
        corporateNumber: "",
        companyName: "",
        prefName: "",
        prefCode: "",
      };
    },
    setEmployees: (state, employees) => {
      state.employees = employees;
    },
    resetEmployees: (state) => {
      state.employees = [];
    },
    setEmployee: (state, employee) => {
      state.permissionLevel = employee.permissionLevel;
    },
    resetEmployee: (state) => {
      state.permissionLevel = -1;
    },
  },
  // storeに保持する値をAPIから取得する場合はactionsでリクエストの実行とmutationsの発火を行う
  actions: {
    async fetchCompany({ commit }) {
      const result = await getCompany();
      if (result.hasError) {
        return;
      }

      commit("setCompany", result.item);
    },

    async fetchEmployees({ getters, commit }) {
      const result = await getEmployeesByCorporateNumber(
        getters.corporateNumber
      );
      if (result.hasError) {
        return;
      }

      commit("setEmployees", result.items);
    },

    async fetchEmployee({ commit }) {
      const result = await getEmployee();
      if (result.hasError) {
        return;
      }

      commit("setEmployee", result.item);
    },

    async initUserInfo({ getters, dispatch }) {
      if (getters.corporateNumber === "") {
        await dispatch("fetchCompany");
      }
      if (getters.permissionLevel === -1) {
        await dispatch("fetchEmployee");
      }
    },
  },
  modules: {},
});
