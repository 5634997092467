<template>
  <div>
    <p v-if="loadFailed">イベントの取得に失敗しました。</p>
    <v-container v-else fluid>
      <v-row align="center">
        <v-col cols="6">
          <v-select
            v-model="selectEventIndex"
            :items="events"
            item-text="text"
            item-value="value"
            single-line
            @change="selectEvent"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "EventSelector",
  props: {
    loadFailed: Boolean,
    events: Array,
  },
  data: () => ({
    // NOTE: v-selectについて
    // v-selectのitem-valueで指定したキー(今回ではvalue)
    // v-selectのv-modelにバインドした変数(今回ではselect)
    // ドロップダウンから選択すると、v-modelの中身が選択した要素のitem-valueに更新される
    // ->v-model側に初期値を入れておくと、item-valueがv-modelの初期値と一致する要素が選択された状態になる
    selectEventIndex: "",
  }),
  methods: {
    selectEvent() {
      this.$emit("change", this.selectEventIndex);
    },
  },
  created() {
    if (!this.loadFailed) {
      this.selectEventIndex = this.events[0].value;
      this.selectEvent();
    }
  },
};
</script>
