<template>
  <div id="disasterEventSendComponent">
    <h1>地震以外災害用メール送信画面</h1>
    <DisasterEventNameInputComponent
      v-model="eventName"
      :companyName="companyName"
    ></DisasterEventNameInputComponent>

    <TargetEmployeesSelectorComponent
      v-model="targetEmployees"
      :loading="isEmployeesLoading"
      :employees="employees"
    >
    </TargetEmployeesSelectorComponent>
    <v-btn
      color="primary"
      :disabled="!eventName || targetEmployees.length === 0"
      @click="postSendMail"
    >
      送信
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { sendMailRequest } from "../apis/mails";
import { formatDatetime } from "../utils/format";

import DisasterEventNameInputComponent from "../components/DisasterEventNameInput";
import TargetEmployeesSelectorComponent from "../components/TargetEmployeesSelector";

export default {
  name: "disasterEventSendComponent",
  components: {
    DisasterEventNameInputComponent,
    TargetEmployeesSelectorComponent,
  },
  data: () => ({
    eventType: "災害安否確認",
    eventName: "",
    targetEmployees: [],
    isEmployeesLoading: true,
  }),
  computed: {
    ...mapGetters([
      "corporateNumber",
      "companyName",
      "permissionLevel",
      "employees",
    ]),
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchEmployees"]),
    /**
     * メール送信APIにPOSTリクエスト処理
     */
    async postSendMail() {
      const params = {
        companyName: this.companyName,
        eventName: this.eventName,
        eventType: this.eventType,
        targetEmployees: this.targetEmployees,
        date: formatDatetime(new Date()),
      };
      const result = await sendMailRequest(this.corporateNumber, params);

      alert(result.message);
      return;
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = `地震以外災害用メール送信画面 | ${this.companyName} 管理画面`;
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }

    if (this.employees.length === 0) {
      await this.fetchEmployees();
    }
    this.isEmployeesLoading = false;
  },
};
</script>
