<template>
  <div>
    <h2>指定日登録</h2>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-date-picker
              v-model="selectedDates"
              :day-format="dayformat"
              style="border: solid 1px #333"
              full-width
              locale="ja-jp"
              multiple
              no-title
              show-adjacent-months
              clearable
            ></v-date-picker>
          </v-col>
          <v-col cols="2">
            <p>選択中の日付</p>
            <ul>
              <li v-for="(selectedDate, i) in sortedSelectedDates" :key="i">
                {{ selectedDate }}
              </li>
            </ul>
          </v-col>
          <v-col cols="2">
            <v-btn color="error" @click="clear" block>選択解除</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2" align-self="center">
            <v-select
              :items="quarterHourIntervalTimes"
              v-model="scheduleTime"
              label="時刻"
              :rules="[inputRules.required]"
            ></v-select>
          </v-col>
          <v-col cols="2" align-self="center">
            <v-btn color="success" @click="regist" block>登録</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import { quarterHourIntervalTimes } from "../consts/times";

export default {
  name: "SchedulesRegisterByDatesComponent",
  props: {},
  data: () => ({
    quarterHourIntervalTimes,
    selectedDates: [],
    scheduleTime: "",
    inputRules: {
      required: (v) => !!v || "選択必須の項目です",
    },
  }),
  computed: {
    sortedSelectedDates() {
      if (this.selectedDates.length === 0) return ["yyyy-mm-dd"];
      return [...this.selectedDates].sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);
        if (aDate < bDate) return -1;
        if (aDate > bDate) return 1;
        return 0;
      });
    },
  },
  methods: {
    headerDateFormat(date) {
      const year = date.split("-")[0];
      const month = date.split("-")[1];
      if (month === undefined) {
        return `${year}年`;
      }
      return `${year}年 ${month}月`;
    },
    monthFormat(date) {
      const month = new Date(date).getMonth();
      return `${month}月`;
    },
    dayformat(date) {
      return new Date(date).getDate();
    },
    regist() {
      if (!this.$refs.form.validate() || this.selectedDates.length === 0) {
        alert("入力内容にエラーがあります");
        return;
      }
      this.$emit("regist", this.selectedDates, this.scheduleTime);
    },
    clear() {
      this.selectedDates = [];
    },
  },
};
</script>
