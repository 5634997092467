<template>
  <div id="SignUpComponent">
    <v-card max-width="500px" class="mx-auto">
      <v-card-title primary-title> パスワード更新 </v-card-title>
      <v-form ref="signUpForm">
        <v-card-text>
          <v-text-field
            name="userName"
            label="ユーザー名"
            id="userName"
            v-model="form.userName"
            :rules="[inputRules.required]"
            required
          ></v-text-field>
          <v-text-field
            name="oldPassword"
            label="仮パスワード"
            id="oldPassword"
            v-model="form.oldPassword"
            :type="showOldPassword ? 'text' : 'password'"
            :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showOldPassword = !showOldPassword"
            :rules="[inputRules.required]"
            required
          ></v-text-field>
          <v-text-field
            name="newPassword"
            label="新パスワード(8文字以上)"
            id="newPassword"
            v-model="form.newPassword"
            :type="showNewPassword ? 'text' : 'password'"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showNewPassword = !showNewPassword"
            :rules="[
              inputRules.required,
              inputRules.minLength8,
              inputRules.letterType,
            ]"
            required
          ></v-text-field>
          <v-card-text class="pa-0 text-caption">
            パスワードに使える文字種は半角英数字と、以下に示す半角記号です<br />
            &nbsp;^$*.[]{}()?\"!@#%&amp;/\,&gt;&lt;':;|_~`=+-
          </v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="changePasswordBySelf"
            :disabled="!isValid"
            class="mx-auto"
            color="info"
          >
            <span v-if="!isLoading">更新</span>
            <v-progress-circular v-else indeterminate size="32" />
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import cognito from "@/cognito";

export default {
  name: "SignUpComponent",
  data: () => ({
    form: {
      userName: "",
      oldPassword: "",
      newPassword: "",
    },
    showOldPassword: false,
    showNewPassword: false,
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
      minLength8: (v) => v.length >= 8 || "8文字以上で入力してください",
      letterType: (v) =>
        cognito.passwordReg.test(v) ||
        "パスワードに使えない文字が含まれています",
    },
    isValid: false,
    isLoading: false,
  }),
  watch: {
    form: {
      handler() {
        this.isValid = this.$refs.signUpForm.validate();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    async changePasswordBySelf() {
      if (!this.$refs.signUpForm.validate()) {
        return;
      }
      this.isLoading = true;
      try {
        await cognito.changePasswordBySelf(
          this.form.userName,
          this.form.oldPassword,
          this.form.newPassword
        );
        alert("パスワードの更新に成功しました。");
        this.redirect();
      } catch (error) {
        console.error(error);
        const messages = ["パスワードの更新に失敗しました。"];
        switch (error.name) {
          case "NotAuthorizedException":
            messages.push(
              "ユーザー名・仮パスワードに間違いがないか確認してください。"
            );
            break;
          case "InvalidPasswordException":
            messages.push("パスワードの要件を満たしていません。");
            break;
          default:
            messages.push(
              "時間を空けて再度実行していただくか、管理者までご連絡ください。",
              `エラーコード: ${error.name}`
            );
        }
        alert(messages.join("\n"));
      }
      this.isLoading = false;
    },
    redirect() {
      this.$router.push(this.$route.query.redirect ?? "/");
    },
  },
  created() {
    this.form.userName = this.$route.query.userName ?? "";
  },
};
</script>
