import { API } from "aws-amplify";
const apiName = "protfupe_admin";

// 法人番号に紐付くイベントデータを指定件数まで、登録日で見て降順に取得
// Lambda側でトークンの中から取り出した法人番号と指定した法人番号が違う場合は特権ユーザーのみ許可
// NOTE: パスパラメータには / が使えないため、 _ に置換する

async function getHealthReports(corporateNumber, escapedDate, limit) {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = `/${corporateNumber}/health_reports/${escapedDate}?limit=${limit}`;
  const myInit = {
    response: true,
  };
  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = false;
    result.message = "体調報告データの取得に失敗しました。";
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}
// NOTE: 新規登録・更新ともにAPIは共通、修正フラグの値はAPI内部で判定
async function updateHealthReport(corporateNumber, params) {
  const result = {
    hasError: false,
    message: "",
  };

  const escapedDate = params.registDate.replace(/\//g, "_");
  params.freeEntryField = params.freeEntryField ?? "";

  const resourcePath = `/${corporateNumber}/health_reports/${escapedDate}`;
  const myInit = {
    response: true,
    body: params,
  };

  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;
    result.message = "体調報告データの修正に失敗しました。";
    return result;
  }

  return result;
}
export { getHealthReports, updateHealthReport };
