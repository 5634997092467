import { API } from "aws-amplify";
const apiName = "protfupe_admin";
import { formatDate } from "../utils/format";
import { days } from "../consts/times";

async function getHealthReportSchedules(corporateNumber) {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = `/${corporateNumber}/health_reports/schedules`;
  const myInit = {
    response: true,
  };
  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = false;
    result.message = "体温報告スケジュールの取得に失敗しました。";
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}

async function registHealthReportSchedulesByDates(
  corporateNumber,
  dateStrings,
  time
) {
  const result = {
    hasError: false,
    message: "",
  };
  const schedules = dateStrings.map((dateString) => {
    const date = new Date(dateString);
    return {
      date: formatDate(date),
      time,
      datetime: `${formatDate(date)}_${time}`,
      day: days[date.getDay()],
    };
  });

  const resourcePath = `/${corporateNumber}/health_reports/schedules`;
  const myInit = {
    response: true,
    body: {
      schedules,
    },
  };
  const apiResponse = await API.put(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = false;
    result.message = "体温報告スケジュールの登録に失敗しました。";
    return result;
  }

  return result;
}

async function registHealthReportSchedulesByConditions(
  corporateNumber,
  weeklyInterval,
  monthlyRange,
  day,
  time
) {
  const result = {
    hasError: false,
    message: "",
  };

  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + monthlyRange);

  const iterationDate = new Date();
  const datesOfRange = [new Date(iterationDate)];
  while (iterationDate < endDate) {
    iterationDate.setDate(iterationDate.getDate() + 1);
    datesOfRange.push(new Date(iterationDate));
  }
  const schedules = datesOfRange
    .filter((date) => days[date.getDay()] === day)
    .filter((_, i) => i % weeklyInterval === 0)
    .map((date) => ({
      date: formatDate(date),
      time,
      datetime: `${formatDate(date)}_${time}`,
      day,
    }));

  const resourcePath = `/${corporateNumber}/health_reports/schedules`;
  const myInit = {
    response: true,
    body: {
      schedules,
    },
  };
  const apiResponse = await API.put(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = false;
    result.message = "体温報告スケジュールの登録に失敗しました。";
    return result;
  }

  return result;
}

async function deleteHealthReportSchedulesByConditions(
  corporateNumber,
  weeklyInterval,
  monthlyRange,
  day,
  time
) {
  const result = {
    hasError: false,
    message: "",
  };

  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + monthlyRange);

  const iterationDate = new Date();
  const datesOfRange = [new Date(iterationDate)];
  while (iterationDate < endDate) {
    iterationDate.setDate(iterationDate.getDate() + 1);
    datesOfRange.push(new Date(iterationDate));
  }
  const schedules = datesOfRange
    .filter((date) => days[date.getDay()] === day)
    .filter((_, i) => i % weeklyInterval === 0)
    .map((date) => ({
      datetime: `${formatDate(date)}_${time}`,
    }));

  const resourcePath = `/${corporateNumber}/health_reports/schedules`;
  const myInit = {
    response: true,
    body: {
      schedules,
    },
  };
  const apiResponse = await API.del(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = false;
    result.message = "体温報告スケジュールの削除に失敗しました。";
    return result;
  }

  return result;
}

async function deleteHealthReportSchedule(corporateNumber, datetime) {
  const result = {
    hasError: false,
    message: "",
  };

  const schedules = [{ datetime }];

  const resourcePath = `/${corporateNumber}/health_reports/schedules`;
  const myInit = {
    response: true,
    body: { schedules },
  };
  const apiResponse = await API.del(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = false;
    result.message = "体温報告スケジュールの削除に失敗しました。";
    return result;
  }

  return result;
}

export {
  getHealthReportSchedules,
  registHealthReportSchedulesByDates,
  registHealthReportSchedulesByConditions,
  deleteHealthReportSchedulesByConditions,
  deleteHealthReportSchedule,
};
