<template>
  <div>
    <h2>回答状況</h2>
    <v-btn color="success" @click="emitRefresh">回答データ再取得</v-btn>
    <v-card>
      <v-alert type="error" :value="loadFailed">
        安否回答データの取得に失敗しました
      </v-alert>

      <v-data-table
        :headers="headers"
        :sort-by="['employeeNo']"
        :items="answers"
        :items-per-page="-1"
        hide-default-footer
        :loading="loading"
        :loading-text="loadingText"
        class="elevation-1 mt-4 corporate-custom answer-table"
      >
        <template v-slot:item="{ item }">
          <tr :class="{ 'row-not-answered': !item.isAnswered }">
            <td>{{ item.employeeNo }}</td>
            <td>{{ item.employeeName }}</td>
            <td>{{ item.cellphoneNumber }}</td>
            <td class="cell-change-color cell-change-content">
              <span :class="getStyleYourStatus(item.yourStatus)">
                {{ item.yourStatus }}
              </span>
            </td>
            <td class="cell-change-color cell-change-content">
              <span :class="getStyleFamilyStatus(item.familyStatus)">
                {{ item.familyStatus }}
              </span>
            </td>
            <td class="cell-change-color cell-change-content">
              {{ item.currentWhereabouts }}
            </td>
            <td class="cell-change-color cell-change-content">
              <span
                :class="getStyleAvailableGoworkTime(item.availableGoworkTime)"
              >
                {{ item.availableGoworkTime }}
              </span>
            </td>
            <td class="cell-change-color cell-change-content">
              {{ item.availableTransportation }}
            </td>
            <td class="cell-change-color cell-change-content">
              <span :class="getStyleHouseStatus(item.houseStatus)">
                {{ item.houseStatus }}
              </span>
            </td>
            <td class="cell-change-color cell-change-content">
              <template v-if="hasGpsInformation(item.gpsInformation)">
                {{ item.gpsInformation.latitude }}<br />
                {{ item.gpsInformation.longitude }}<br />
                <!-- NOTE: APIキーの取得等の必要がありそうなので一旦外部リンク -->
                <a :href="mapLink(item.gpsInformation)" target="_blank"
                  >地図で確認する</a
                >
              </template>
              <span v-else> - </span>
            </td>
            <td class="wide-cell cell-change-color cell-change-content">
              {{ item.freeEntryfield }}
            </td>
            <td class="cell-change-color cell-change-content">
              {{ item.lastUpdateDate.split(" ")[0] }}<br />
              {{ item.lastUpdateDate.split(" ")[1] }}
            </td>
            <td class="cell-change-color">{{ item.resendCount }}</td>
            <!-- NOTE: 回答済みであっても再送信ボタンの表示は行う -->
            <td>
              <!-- NOTE: 回答済みであっても再送信ボタンの表示は行う -->
              <v-icon small class="mr-2" @click="resendMail(item)">
                mdi-email
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "SafetyConfirmationTableComponent",
  props: {
    loading: Boolean,
    loadFailed: Boolean,
    answers: Array,
  },
  data: () => ({
    loadingText: "回答状況データを取得中です",
    // NOTE: ソートに使えるのは社員Noと最終更新日時
    headers: [
      { text: "社員No", value: "employeeNo" },
      { text: "社員名", value: "employeeName", sortable: false },
      { text: "電話番号", value: "cellphoneNumber", sortable: false },
      { text: "本人の状況", value: "yourStatus", sortable: false },
      { text: "家族の状況", value: "familyStatus", sortable: false },
      { text: "現在の居場所", value: "currentWhereabouts", sortable: false },
      { text: "出社可能時間", value: "availableGoworkTime", sortable: false },
      {
        text: "利用可能な交通手段",
        value: "availableTransportation",
        sortable: false,
      },
      { text: "自宅の状況", value: "houseStatus", sortable: false },
      { text: "GPS情報", value: "gpsInformation", sortable: false },
      {
        text: "自由記入欄",
        value: "freeEntryfield",
        sortable: false,
      },
      { text: "最終更新日時", value: "lastUpdateDate" },
      { text: "再送信回数", value: "resendCount", sortable: false },
      // NOTE: v-slotで追加する列
      // 回答データにresendという項目を持たせるわけではない
      { text: "再送信", value: "resend", sortable: false },
    ],
  }),
  methods: {
    emitRefresh() {
      this.$emit("refresh");
    },
    resendMail(item) {
      this.$emit("resend-mail", item);
    },
    getStyleYourStatus(yourStatus) {
      switch (yourStatus) {
        case "重症":
          return "cell-dangerous";

        case "軽症":
          return "cell-caution";

        default:
          return "";
      }
    },
    getStyleFamilyStatus(familyStatus) {
      switch (familyStatus) {
        case "重症":
          return "cell-dangerous";

        case "軽症":
          return "cell-caution";

        default:
          return "";
      }
    },
    getStyleHouseStatus(houseStatus) {
      switch (houseStatus) {
        case "住めない":
          return "cell-dangerous";

        default:
          return "";
      }
    },
    getStyleAvailableGoworkTime(availableGoworkTime) {
      switch (availableGoworkTime) {
        case "本日は出社不可":
          return "cell-dangerous";

        case "2時間以上":
          return "cell-caution";

        default:
          return "";
      }
    },
    hasGpsInformation(gpsInformation) {
      if (
        typeof gpsInformation === "object" &&
        typeof gpsInformation.latitude === "number" &&
        typeof gpsInformation.longitude === "number"
      ) {
        return true;
      }
      return false;
    },
    mapLink(gpsInformation) {
      return `https://www.google.co.jp/maps/place/${gpsInformation.latitude},${gpsInformation.longitude}?hl=ja`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/table.scss";
</style>
