import { API } from "aws-amplify";
const apiName = "protfupe_admin";

// params = {
//     eventName: String,
//     eventType: String,
//     subject: String,
//     messageTemplate: String,
//     links: Array<link>,
//     targetEmployees: Array<Employee>,
//     date: "YYYY/MM/dd hh:mm:ss",
// }
//
// link = {
//   placeholder: String,
//   text: String,
//   url: String
// }
async function sendMailRequest(corporateNumber, params) {
  const result = {
    hasError: false,
    message: "",
  };

  const resourcePath = `/${corporateNumber}/safety_confirmations/send_mails/`;
  const myInit = {
    // ステータスコードなども取得できるオプション
    // レスポンスのbodyはdataというキーで入っている
    response: true,
    body: params,
  };
  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;

    // NOTE: valuesに具体的な値を入れて返している
    // メッセージのカスタマイズが必要な場合にはvaluesから値を取り出す
    // console.log(apiResponse.error.response.data.values);

    // NOTE: エラー内容に即したメッセージはAPI側で作成
    // フロントでは表示のみ
    result.message = apiResponse.error.response.data.message;
    return result;
  }

  result.message = apiResponse.data.message;
  return result;
}
async function resendMailRequest(corporateNumber, item) {
  const result = {
    hasError: false,
    message: "",
  };
  const confirmMessage = `イベント:[${item.eventName}]\n${item.employeeName}(${item.email})宛てにメールを再送信しますか？`;
  if (confirm(confirmMessage)) {
    const resourcePath = `/${corporateNumber}/safety_confirmations/resend_mail/`;
    const params = {
      answerItem: item,
    };
    const myInit = {
      response: true,
      body: params,
    };
    const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
      (e) => ({
        error: e,
      })
    );
    if (apiResponse.error) {
      result.hasError = true;
      result.message = "再送信に失敗しました。";
      return result;
    }

    result.message = "再送信しました。";
    return result;
  }
  return result;
}

async function sendHealthReportMailsRequest(corporateNumber) {
  const result = {
    hasError: false,
    message: "",
  };

  const resourcePath = `/${corporateNumber}/health_reports/send_mails/`;
  const myInit = {
    response: true,
  };
  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;
    result.message = apiResponse.error.response.data.message;
    return result;
  }

  result.message = apiResponse.data.message;
  return result;
}

async function resendHealthReportMailsRequest(corporateNumber, targetAddress) {
  const result = {
    hasError: false,
    message: "",
  };

  const resourcePath = `/${corporateNumber}/health_reports/resend_mail/`;
  const myInit = {
    response: true,
    body: { targetAddress },
  };
  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;
    result.message = apiResponse.error.response.data.message;
    return result;
  }

  result.message = apiResponse.data.message;
  return result;
}

export {
  sendMailRequest,
  resendMailRequest,
  sendHealthReportMailsRequest,
  resendHealthReportMailsRequest,
};
