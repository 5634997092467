import { formatTime } from "../utils/format";

const weeklyIntervals = [
  { text: "毎週", value: 1 },
  { text: "隔週", value: 2 },
  { text: "3週間ごと", value: 3 },
  { text: "4週間ごと", value: 4 },
];
const monthlyRanges = [
  { text: "1ヶ月先まで", value: 1 },
  { text: "2ヶ月先まで", value: 2 },
  { text: "3ヶ月先まで", value: 3 },
  { text: "半年先まで", value: 6 },
  { text: "1年先まで", value: 12 },
];
const days = ["日", "月", "火", "水", "木", "金", "土"];

const baseDate = new Date();
baseDate.setHours(23, 45);

const quarterHourIntervalTimes = [...Array(24 * 4)].map(() => {
  baseDate.setMinutes(baseDate.getMinutes() + 15);
  return formatTime(baseDate);
});

export { weeklyIntervals, monthlyRanges, days, quarterHourIntervalTimes };
