<template>
  <div>
    <h2>権限不足です。</h2>
    <p>5秒後にTOPページへ遷移します。</p>
  </div>
</template>
<script>
export default {
  name: "DeniedComponent",
  created() {
    setTimeout(() => this.$router.push("/"), 5000);
  },
};
</script>
