import { API } from "aws-amplify";
const apiName = "protfupe_admin";

// 法人番号から会社データを取得
// 法人番号はトークンの中からLambda側で取り出す
async function getCompany() {
  const result = {
    hasError: false,
    item: {},
  };

  const resourcePath = "/company";
  const myInit = {
    response: true,
  };
  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    return result;
  }

  result.item = apiResponse.data.item;
  return result;
}

// 全会社データを取得
async function getCompanies() {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = "/companies";
  const myInit = {
    response: true,
  };
  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    result.message = "企業一覧の取得に失敗しました";
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}

async function registCompany(company) {
  const result = {
    hasError: false,
    message: "",
  };

  const corporateNumber = company.corporateNumber;
  const params = {
    companyName: company.companyName,
    prefName: company.prefName,
    prefCode: company.prefCode,
  };

  const resourcePath = `/${corporateNumber}/company`;
  const myInit = {
    response: true,
    body: params,
  };
  const apiResponse = await API.put(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    result.message = "企業情報の登録に失敗しました。";
    return result;
  }

  return result;
}

async function updateCompany(company) {
  const result = {
    hasError: false,
    message: "",
  };

  const corporateNumber = company.corporateNumber;
  const params = {
    companyName: company.companyName,
    prefName: company.prefName,
    prefCode: company.prefCode,
  };

  const resourcePath = `/${corporateNumber}/company`;
  const myInit = {
    response: true,
    body: params,
  };
  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    result.message = "企業情報の更新に失敗しました。";
    return result;
  }

  return result;
}

export { getCompany, getCompanies, registCompany, updateCompany };
