<template>
  <div>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="6">
          <v-select
            v-model="selectedDate"
            :items="dates"
            item-text="text"
            item-value="value"
            label="日付"
            single-line
            @change="selectDate"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { formatDate } from "../utils/format";

export default {
  name: "DateSelector",
  data: () => ({
    selectedDate: "",
    dates: [],
  }),
  methods: {
    selectDate() {
      this.$emit("change", this.selectedDate);
    },
  },
  created() {
    this.dates = [...Array(10)].map((_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return formatDate(date);
    });
    this.selectedDate = formatDate(new Date());
    this.selectDate();
  },
};
</script>
