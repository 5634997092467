<template>
  <div>
    <h1>企業情報編集画面</h1>
    <DeniedComponent
      v-if="isPermissionCheckCompleted && !isSuperUser"
    ></DeniedComponent>
    <template v-if="isSuperUser">
      <h2>対象の企業</h2>
      <CompanyRegisterComponent
        ref="companyRegister"
        @save="registCompany"
      ></CompanyRegisterComponent>
      <v-select
        :items="companies"
        item-text="companyName"
        item-value="corporateNumber"
        v-model="selecetedCompanyCorporateNumber"
        label="編集する企業"
        @change="refreshEmployees"
      ></v-select>
      <h2>企業情報</h2>
      <CompanyEditTableComponent
        ref="companyEditTable"
        :company="selecetedCompany"
        :loading="companiesLoading"
        @save="saveCompany"
      ></CompanyEditTableComponent>
      <h2>拠点一覧</h2>
      <BranchOfficeRegisterComponent
        ref="branchOfficeRegister"
        :corporateNumber="selecetedCompanyCorporateNumber"
        @save="registBranchOffice"
      ></BranchOfficeRegisterComponent>
      <BranchOfficeEditTableComponent
        ref="branchOfficeEditTable"
        :branchOffices="branchOffices"
        :loading="branchOfficesLoading"
        @save="saveBranchOffice"
        @delete="deleteBranchOffice"
      ></BranchOfficeEditTableComponent>
      <h2>社員一覧</h2>
      <EmployeesEditTableComponent
        ref="employeeEditTable"
        :company="selecetedCompany"
        :branchOffices="branchOffices"
        :employees="employees"
        :loading="employeesLoading"
        @save="saveEmployee"
        @refresh="refreshEmployees"
        @republish-temporary-password="republishTemporaryPassword"
      ></EmployeesEditTableComponent>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { getCompanies, registCompany, updateCompany } from "../apis/companies";
import {
  getBranchOfficesByCorporateNumber,
  registBranchOffice,
  updateBranchOffice,
  deleteBranchOffice,
} from "../apis/branchOffices";
import {
  getEmployeesByCorporateNumber,
  updateEmployee,
} from "../apis/employees";
import { republishTemporaryPassword } from "../apis/password";

import DeniedComponent from "../components/Denied.vue";
import CompanyRegisterComponent from "../components/CompanyRegister.vue";
import CompanyEditTableComponent from "../components/CompanyEditTable.vue";
import BranchOfficeRegisterComponent from "../components/BranchOfficeRegister.vue";
import BranchOfficeEditTableComponent from "../components/BranchOfficeEdit.vue";
import EmployeesEditTableComponent from "../components/EmployeesEditTable.vue";

export default {
  name: "PrivilegeEditCompany",
  components: {
    DeniedComponent,
    CompanyRegisterComponent,
    CompanyEditTableComponent,
    BranchOfficeRegisterComponent,
    BranchOfficeEditTableComponent,
    EmployeesEditTableComponent,
  },
  data: () => ({
    companies: [],
    branchOffices: [],
    employees: [],
    selecetedCompanyCorporateNumber: "",
    companiesLoading: true,
    branchOfficesLoading: true,
    employeesLoading: true,
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    isPermissionCheckCompleted() {
      return this.permissionLevel !== -1;
    },
    isSuperUser() {
      return this.permissionLevel === 2;
    },
    selecetedCompany() {
      if (this.selecetedCompanyCorporateNumber === "") {
        return {};
      }

      return this.companies.find(
        (company) =>
          company.corporateNumber === this.selecetedCompanyCorporateNumber
      );
    },
  },
  watch: {
    selecetedCompanyCorporateNumber: function (val) {
      if (!val) return;
      this.getBranchOffices();
      this.refreshEmployees();
    },
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchEmployee"]),
    async getCompanies() {
      const result = await getCompanies();
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.companies = result.items;
    },
    async registCompany(company) {
      const result = await registCompany(company);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      await this.getCompanies();
      this.selecetedCompanyCorporateNumber = company.corporateNumber;

      this.$refs.companyRegister.closeRegistModal();
    },
    async saveCompany(company) {
      const result = await updateCompany(company);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getCompanies();
      this.$refs.companyEditTable.closeEditModal();
    },
    async getBranchOffices() {
      this.branchOfficesLoading = true;
      const result = await getBranchOfficesByCorporateNumber(
        this.selecetedCompanyCorporateNumber
      );
      if (result.hasError) {
        this.branchOfficesLoading = false;
        alert(result.message);
        return;
      }
      this.branchOffices = result.items;
      this.branchOfficesLoading = false;
    },
    async registBranchOffice(branchOffice) {
      const result = await registBranchOffice(branchOffice);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getBranchOffices();
      this.$refs.branchOfficeRegister.closeRegistModal();
    },
    async saveBranchOffice(branchOffice) {
      const result = await updateBranchOffice(branchOffice);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getBranchOffices();
      this.$refs.branchOfficeEditTable.closeEditModal();
    },
    async deleteBranchOffice(branchOfficeKey) {
      const result = await deleteBranchOffice(branchOfficeKey);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getBranchOffices();
      this.$refs.branchOfficeEditTable.closeDeleteModal();
    },
    async getEmployees(corporateNumber) {
      this.employeesLoading = true;
      const result = await getEmployeesByCorporateNumber(corporateNumber);
      if (result.hasError) {
        alert(result.message);
        this.employeesLoading = false;
        return;
      }
      this.employees = result.items;
      this.employeesLoading = false;
    },
    async saveEmployee(employee) {
      const result = await updateEmployee(employee);
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getEmployees(employee.corporateNumber);
      this.$refs.employeeEditTable.closeEditModal();
    },
    async refreshEmployees() {
      if (this.selecetedCompanyCorporateNumber === "") return;
      await this.getEmployees(this.selecetedCompanyCorporateNumber);
    },
    async republishTemporaryPassword(employee, isAdminPool) {
      const result = await republishTemporaryPassword(employee, isAdminPool);
      alert(result.message);
      if (!result.hasError) {
        this.$refs.employeeEditTable.closeRepublishTemporaryPasswordDialog();
      }
    },
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }
    if (this.permissionLevel < 2) {
      return;
    }

    await this.getCompanies();
    this.selecetedCompanyCorporateNumber = this.corporateNumber;
    this.companiesLoading = false;
  },
};
</script>
<style scoped></style>
