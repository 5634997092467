import { API } from "aws-amplify";
const apiName = "protfupe_admin";

// 法人番号に紐付くイベントデータを指定件数まで、登録日で見て降順に取得
// Lambda側でトークンの中から取り出した法人番号と指定した法人番号が違う場合は特権ユーザーのみ許可
async function getEvents(corporateNumber, limit) {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = `/${corporateNumber}/events?limit=${limit}`;
  const myInit = {
    response: true,
  };
  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = false;
    result.message = "イベントデータの取得に失敗しました。";
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}

export { getEvents };
