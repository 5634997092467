<template>
  <div id="app" :style="corporateStyle">
    <v-app>
      <v-app-bar app clipped-left color="white">
        <v-app-bar-nav-icon
          v-if="isRequireAuthPage"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>{{ headerCompanyName }} 管理画面</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="signOut" v-if="isRequireAuthPage" color="error">
          <span v-if="!isLoading">サインアウト</span>
          <v-progress-circular v-else indeterminate size="32" />
        </v-btn>
      </v-app-bar>

      <v-main>
        <v-navigation-drawer
          v-if="isRequireAuthPage"
          app
          clipped
          v-model="drawer"
          mobile-breakpoint="240"
        >
          <v-list dense nav>
            <v-list-item
              v-for="item in navigationItems"
              :key="item.displayName"
              :to="item.path"
              link
            >
              <!--           
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              -->

              <v-list-item-content>
                <v-list-item-title>{{ item.displayName }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <router-view class="pa-4" @forceSignOut="forceSignOut" />
      </v-main>
      <v-footer app></v-footer>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import cognito from "@/cognito";

export default {
  name: "App",
  data: () => ({
    allNavigationItems: [
      {
        path: "/",
        displayName: "TOPページ",
      },
      {
        path: "/EditCompany",
        displayName: "企業情報編集画面",
      },
      {
        path: "/SafetyConfirmation",
        displayName: "安否確認画面",
      },
      {
        path: "/BodyTemperature",
        displayName: "体温確認画面",
      },
      {
        path: "/DisasterEventSend",
        displayName: "地震以外災害用メール送信画面",
      },
      {
        path: "/privilege/EditCompany",
        displayName: "特権ユーザー用 企業情報編集画面",
        forSuperUser: true,
      },
      {
        path: "/privilege/HealthReportSchedule",
        displayName: "特権ユーザー用 体温報告スケジュール登録画面",
        forSuperUser: true,
      },
      {
        path: "/privilege/SafetyConfirmation",
        displayName: "特権ユーザー用 安否確認画面",
        forSuperUser: true,
      },
      {
        path: "/privilege/BodyTemperature",
        displayName: "特権ユーザー用 体温確認画面",
        forSuperUser: true,
      },
      {
        path: "/privilege/DisasterEventSend",
        displayName: "特権ユーザー用 地震以外災害用メール送信画面",
        forSuperUser: true,
      },
    ],
    drawer: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters(["companyName", "permissionLevel"]),
    isRequireAuthPage() {
      const authPages = ["/SignIn", "/SignUp", "/PasswordReset"];
      return !authPages.includes(this.$route.path);
    },
    headerCompanyName() {
      return this.isRequireAuthPage ? this.companyName : "安否確認システム";
    },
    // 特権ユーザー以外には特権ユーザー用ページへの導線を表示しない
    navigationItems() {
      return this.allNavigationItems.filter((item) =>
        this.isDisplayNavigationItem(item)
      );
    },
    // TODO: 会社マスタから呼び出したものを右辺値として利用する予定
    corporateStyle() {
      return {
        "--th-background-color": "#0070c0",
        "--th-font-color": "#ffffff",
      };
    },
  },
  watch: {
    drawer: async function (newVal) {
      if (newVal === true && this.permissionLevel === -1) {
        await this.fetchEmployee();
      }
    },
  },
  methods: {
    ...mapMutations(["resetCompany", "resetEmployee"]),
    ...mapActions(["fetchEmployee"]),
    signOut() {
      this.isLoading = true;
      alert("サインアウトします");
      this.resetCompany();
      this.resetEmployee();
      this.drawer = false;
      cognito
        .signOut()
        .catch((e) => {
          console.error(e);
          alert("サインアウトに失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
          this.$router.push("/SignIn");
        });
    },
    forceSignOut() {
      alert("権限不足です");
      this.resetCompany();
      this.resetEmployee();
      this.drawer = false;
      cognito
        .signOut()
        .catch(() => alert("サインアウトに失敗しました"))
        .finally(() => this.$router.push("/SignIn"));
    },
    isDisplayNavigationItem(navigationItem) {
      if (navigationItem.forSuperUser) {
        return this.permissionLevel === 2;
      }

      return true;
    },
  },
};
</script>
