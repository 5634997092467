<template>
  <div id="BodyTemperatureComponent">
    <h1>体温確認状況</h1>
    <h2>体温確認状況</h2>
    <DateSelector @change="selectDate"> </DateSelector>
    <HealthReportTableComponent
      ref="healthReportTable"
      :loading="healthReportsLoading"
      :loadFailed="healthReportsLoadFailed"
      :healthReports="selectedDateHealthReports"
      @save="updateHealthReport"
      @refresh="getHealthReports"
      @resend-mail="sendHealthReportMailsRequest"
    >
    </HealthReportTableComponent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getHealthReports, updateHealthReport } from "../apis/healthReports";
import {
  sendHealthReportMailsRequest,
  resendHealthReportMailsRequest,
} from "../apis/mails";
import { formatDate } from "../utils/format";
import DateSelector from "../components/DateSelector";
import HealthReportTableComponent from "../components/HealthReportTable";

export default {
  name: "BodyTemperatureComponent",
  components: {
    DateSelector,
    HealthReportTableComponent,
  },
  data: () => ({
    healthReports: [],
    healthReportsLoading: true,
    healthReportsLoadFailed: false,
    //createで当日の日付を設定
    selectedDate: "",
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    ...mapGetters({
      storedEmployees: "employees",
    }),
    formatToday() {
      return formatDate(new Date());
    },
    selectedDateHealthReports() {
      if (this.healthReportsLoading || this.healthReportsLoadFailed) {
        return [];
      }
      const existHealthReports = this.healthReports.filter(
        (item) => item.registDate === this.selectedDate
      );
      const answeredEmployeeCellphoneNumbers = existHealthReports.map(
        (report) => report.cellphoneNumber
      );
      const notAnsweredEmployees = this.storedEmployees.filter(
        (employee) =>
          !answeredEmployeeCellphoneNumbers.includes(employee.cellphoneNumber)
      );
      const dummyHealthReports = notAnsweredEmployees.map((employee) => ({
        cellphoneNumber: employee.cellphoneNumber,
        employeeNo: employee.employeeNo,
        employeeName: employee.name,
        email: employee.email,
        registDate: this.selectedDate,
      }));

      return existHealthReports.concat(dummyHealthReports);
    },
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchEmployees"]),
    async getHealthReports() {
      const escapedDate = this.formatToday.replace(/\//g, "_");
      const result = await getHealthReports(
        this.corporateNumber,
        escapedDate,
        10
      );
      this.healthReportsLoading = false;

      if (result.hasError) {
        this.healthReportsLoadFailed = true;
        alert(result.message);
        return;
      }

      this.healthReports = result.items;
    },
    selectDate(selectedDate) {
      this.selectedDate = selectedDate;
    },
    async updateHealthReport(healthReport) {
      const result = await updateHealthReport(
        this.corporateNumber,
        healthReport
      );
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.getHealthReports();
      this.$refs.healthReportTable.closeEditModal();
    },
    // NOTE: 体温報告メールの自動送信の実装がまだのため、手動送信用に仮置き
    // 再送信の場合にはtargetAddressが指定され、リクエスト先のAPIが再送信用のものに切り替わる
    async sendHealthReportMailsRequest(targetAddress = null) {
      // 全社員に送信
      if (targetAddress === null) {
        const result = await sendHealthReportMailsRequest(this.corporateNumber);
        alert(result.message);
        return;
      }

      // 指定された社員にのみ再送信
      const result = await resendHealthReportMailsRequest(
        this.corporateNumber,
        targetAddress
      );
      alert(result.message);
      return;
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = `体温確認状況 | ${this.companyName} 管理画面`;
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }

    if (this.storedEmployees.length === 0) {
      this.fetchEmployees();
    }
    await this.getHealthReports();
  },
};
</script>
