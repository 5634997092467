<template>
  <div>
    <h2>一括登録・削除</h2>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="pt-0" cols="3" align-self="center">
            <v-select
              :items="weeklyIntervals"
              v-model="weeklyInterval"
              label="頻度"
              :rules="[inputRules.required]"
            ></v-select>
          </v-col>
          <v-col class="pt-0" cols="3" align-self="center">
            <v-select
              :items="monthlyRanges"
              v-model="monthlyRange"
              label="期間"
              :rules="[inputRules.required]"
            ></v-select>
          </v-col>
          <v-col class="pt-0" cols="2" align-self="center">
            <v-select
              :items="days"
              v-model="scheduleDay"
              label="曜日"
              :rules="[inputRules.required]"
            ></v-select>
          </v-col>
          <v-col class="pt-0" cols="2" align-self="center">
            <v-select
              :items="quarterHourIntervalTimes"
              v-model="scheduleTime"
              label="時刻"
              :rules="[inputRules.required]"
            ></v-select>
          </v-col>
          <v-col class="pt-0" cols="2" align-self="center">
            <v-btn color="success" @click="regist" block> 登録 </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="pt-0" cols="2" align-self="center">
            <v-btn color="error" @click="deleteSchedules" block> 削除 </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import {
  weeklyIntervals,
  monthlyRanges,
  days,
  quarterHourIntervalTimes,
} from "../consts/times";
export default {
  name: "SchedulesRegisterByConditionsComponent",
  data: () => ({
    weeklyIntervals,
    monthlyRanges,
    days,
    quarterHourIntervalTimes,
    weeklyInterval: "",
    monthlyRange: "",
    scheduleDay: "",
    scheduleTime: "",
    inputRules: {
      required: (v) => !!v || "選択必須の項目です",
    },
  }),
  methods: {
    regist() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります");
        return;
      }
      this.$emit(
        "regist",
        this.weeklyInterval,
        this.monthlyRange,
        this.scheduleDay,
        this.scheduleTime
      );
    },
    deleteSchedules() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります");
        return;
      }
      this.$emit(
        "delete",
        this.weeklyInterval,
        this.monthlyRange,
        this.scheduleDay,
        this.scheduleTime
      );
    },
  },
};
</script>
