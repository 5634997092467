<template>
  <div>
    <h2>TOPメッセージライン</h2>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="eventName"
            label="イベント名"
            :rules="[rules.required, rules.length]"
            @input="$emit('input', eventName)"
          ></v-text-field>
          <p>{{ fixedPhrase }}</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "DisasterEventNameInputComponent",
  props: {
    companyName: String,
  },
  data: () => ({
    eventName: "",
    rules: {
      required: (v) => !!v || "必須項目です",
      length: (v) => v.length < 100 || "100文字以内にしてください",
    },
  }),
  computed: {
    fixedPhrase() {
      return `により安否確認が${this.companyName}より要請されました。`;
    },
  },
};
</script>
