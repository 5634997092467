import { API } from "aws-amplify";
const apiName = "protfupe_admin";

// 法人番号に紐付く安否回答データを全て取得
// Lambda側でトークンの中から取り出した法人番号と指定した法人番号が違う場合は特権ユーザーのみ許可
async function getSafetyConfirmations(coporateNumber) {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = `/${coporateNumber}/safety_confirmations`;
  const myInit = {
    response: true,
  };

  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    result.message = "安否回答データの取得に失敗しました。";
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}

export { getSafetyConfirmations };
