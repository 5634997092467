<template>
  <div>
    <h1>体温報告スケジュール登録画面</h1>
    <v-divider class="pb-4"></v-divider>
    <DeniedComponent
      v-if="isPermissionCheckCompleted && !isSuperUser"
    ></DeniedComponent>
    <template v-if="isSuperUser">
      <h2>対象の企業</h2>
      <v-container>
        <v-row>
          <v-col cols="8">
            <v-select
              :items="companies"
              item-text="companyName"
              item-value="corporateNumber"
              v-model="selecetedCompanyCorporateNumber"
              label="スケジュール登録を行う企業"
              @change="
                getSchedules();
                calenderClear();
              "
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
      <v-divider class="pb-4"></v-divider>
      <SchedulesRegisterByDatesComponent
        ref="schedulesRegisterByDates"
        @regist="registSchedulesByDates"
      ></SchedulesRegisterByDatesComponent>
      <v-divider class="pb-4"></v-divider>
      <SchedulesRegisterByConditionsComponent
        @regist="registSchedulesByConditions"
        @delete="deleteSchedulesByConditions"
      ></SchedulesRegisterByConditionsComponent>
      <v-divider class="pb-4"></v-divider>
      <SchedulesTableComponent
        :schedules="schedules"
        :loading="schedulesLoading"
        @delete="deleteSchedule"
      ></SchedulesTableComponent>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { getCompanies } from "../apis/companies";
import {
  getHealthReportSchedules,
  registHealthReportSchedulesByDates,
  registHealthReportSchedulesByConditions,
  deleteHealthReportSchedulesByConditions,
  deleteHealthReportSchedule,
} from "../apis/healthReportSchedules";

import DeniedComponent from "../components/Denied";
import SchedulesRegisterByDatesComponent from "../components/SchedulesRegisterByDates";
import SchedulesRegisterByConditionsComponent from "../components/SchedulesRegisterByConditions";
import SchedulesTableComponent from "../components/SchedulesTable";

export default {
  name: "HealthReportSchedule",
  components: {
    DeniedComponent,
    SchedulesRegisterByDatesComponent,
    SchedulesRegisterByConditionsComponent,
    SchedulesTableComponent,
  },
  data: () => ({
    companies: [],
    selecetedCompanyCorporateNumber: "",
    companiesLoading: true,

    schedules: [],
    schedulesLoading: true,
  }),
  computed: {
    ...mapGetters(["corporateNumber", "companyName", "permissionLevel"]),
    isPermissionCheckCompleted() {
      return this.permissionLevel !== -1;
    },
    isSuperUser() {
      return this.permissionLevel === 2;
    },
    selecetedCompany() {
      if (this.selecetedCompanyCorporateNumber === "") {
        return {};
      }

      return this.companies.find(
        (company) =>
          company.corporateNumber === this.selecetedCompanyCorporateNumber
      );
    },
  },
  methods: {
    ...mapActions(["initUserInfo", "fetchEmployee"]),
    async getCompanies() {
      const result = await getCompanies();
      if (result.hasError) {
        alert(result.message);
        return;
      }
      this.companies = result.items;
    },
    async getSchedules() {
      this.schedulesLoading = true;
      const result = await getHealthReportSchedules(
        this.selecetedCompanyCorporateNumber
      );
      if (result.hasError) {
        alert(result.message);
        this.schedules = [];
        this.schedulesLoading = false;
        return;
      }

      this.schedules = result.items;
      this.schedulesLoading = false;
    },
    async registSchedulesByDates(dateStrings, time) {
      const result = await registHealthReportSchedulesByDates(
        this.selecetedCompanyCorporateNumber,
        dateStrings,
        time
      );
      if (result.hasError) {
        alert(result.message);
        return;
      }
      alert("スケジュールの登録に成功しました");
      this.getSchedules();
      this.calenderClear();
    },
    async registSchedulesByConditions(weeklyInterval, monthlyRange, day, time) {
      const result = await registHealthReportSchedulesByConditions(
        this.selecetedCompanyCorporateNumber,
        weeklyInterval,
        monthlyRange,
        day,
        time
      );
      if (result.hasError) {
        alert(result.message);
        return;
      }
      alert("スケジュールの登録に成功しました");
      this.getSchedules();
    },
    async deleteSchedulesByConditions(weeklyInterval, monthlyRange, day, time) {
      const result = await deleteHealthReportSchedulesByConditions(
        this.selecetedCompanyCorporateNumber,
        weeklyInterval,
        monthlyRange,
        day,
        time
      );
      if (result.hasError) {
        alert(result.message);
        return;
      }
      alert("スケジュールの削除に成功しました");
      this.getSchedules();
    },
    async deleteSchedule(schedule) {
      const result = await deleteHealthReportSchedule(
        schedule.corporateNumber,
        schedule.datetime
      );
      if (result.hasError) {
        alert(result.message);
        return;
      }

      alert("スケジュールの削除に成功しました");
      this.getSchedules();
    },
    calenderClear() {
      this.$refs.schedulesRegisterByDates.clear();
    },
  },
  async created() {
    await this.initUserInfo();
    if (this.permissionLevel < 1) {
      this.$emit("forceSignOut");
      return;
    }
    if (this.permissionLevel < 2) {
      return;
    }

    await this.getCompanies();
    this.selecetedCompanyCorporateNumber = this.corporateNumber;
    this.companiesLoading = false;

    await this.getSchedules();
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/table.scss";
</style>
