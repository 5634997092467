<template>
  <div>
    <v-btn color="success" @click="openRegistModal">新規登録</v-btn>
    <v-dialog
      v-model="showDialog"
      scrollable
      draggable="true"
      persistent
      no-click-animation
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-form ref="form">
        <v-card class="mt-4">
          <v-card-title primary-title> 企業登録 </v-card-title>
          <v-card-text> ※法人番号は登録後には修正できません。</v-card-text>
          <v-card-text>
            <v-text-field
              label="法人番号"
              name="corporateNumber"
              textarea
              v-model="modalItem.corporateNumber"
              :rules="[
                inputRules.required,
                inputRules.numberOnly,
                inputRules.length_13,
              ]"
            ></v-text-field>
            <v-text-field
              label="企業名"
              name="companyName"
              textarea
              v-model="modalItem.companyName"
              :rules="[inputRules.required]"
            ></v-text-field>
            <v-select
              :items="prefs"
              item-text="prefName"
              v-model="modalItem.prefName"
              label="会社所在地"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="saveItem" color="info">登録</v-btn>
            <v-btn @click="closeRegistModal" color="error"> キャンセル </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import prefs from "../consts/prefs.js";
export default {
  name: "CompanyRegisterComponent",
  props: {},
  data: () => ({
    showDialog: false,
    modalItem: {
      corporateNumber: "",
      companyName: "",
      prefName: "",
      prefCode: "",
    },
    prefs,
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
      numberOnly: (v) => /^\d+$/.test(v) || "半角数字のみで入力してください",
      length_13: (v) => v.length === 13 || "13桁で入力してください",
    },
  }),
  methods: {
    openRegistModal() {
      this.showDialog = true;
    },
    closeRegistModal() {
      this.showDialog = false;
      this.$refs.form.resetValidation();
      this.resetModalItem();
    },
    resetModalItem() {
      this.modalItem = {
        corporateNumber: "",
        companyName: "",
      };
    },
    saveItem() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります");
        return;
      }

      this.modalItem.prefCode = this.prefs.find(
        (pref) => pref.prefName === this.modalItem.prefName
      ).prefCode;
      this.$emit("save", this.modalItem);
    },
  },
};
</script>
